import supportStyles from "./supportApplicationPage.module.css";
import React, { ChangeEvent, useState } from "react";
import Modal from "../../components/modals/modal/Modal";
import SupportSuccessPopup from "../../components/modals/support_success_popup/support_success_popup";
import { supportMessageInitialState, techSupportThemes } from "../../utils/constants";
// import {
//   useSendTechSupportRequestMutation,
//   useSendCustomerServiceRequestMutation,
// } from "../../services/api/requests.api";
// import { useAppSelector } from "../../services/hooks";
// import { errorRequestNumberSelector } from "../../services/selectors/requests.selectors";
import { customerService } from "../../utils/constants";
import { usePostSupportMessageMutation } from "../../services/api/user.api";
import { useAppSelector } from "../../services/hooks";
import { userSelector } from "../../services/selectors/user.selectors";

function SupportApplicationPage({ type }: { type?: string }) {
  const [form, setForm] = useState(supportMessageInitialState);
  const [openModal, setOpenModal] = useState(false);
  const [showThemes, setShowThemes] = useState(false);

  const title = type === customerService ? "שירות לקוחות" : "תמיכה טכנית";

  const disabled = !form.subject || !form.body;

  const [sendTechSupportRequest, { isError: isTechSupportError }] =
    usePostSupportMessageMutation();
  // const [sendCustomerServiceRequest, { isError: isCustomerServiceError }] =
  //   useSendCustomerServiceRequestMutation();

  const user = useAppSelector(userSelector)
  const updateError = "משהו השתבש, אנא נסה שוב מאוחר יותר";

  function handleChange(
    evt: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) {
    const value = evt.target.value;
    setForm({
      ...form,
      [evt.target.name]: value,
    });
  }
  const submitForm = (e: React.FormEvent) => {
    e.preventDefault();
    if (type !== customerService) {
      sendTechSupportRequest({
        ...form,
        companyId: user.companyId,
        createdUsersId: user.id,
      })
        .then((res) => {
          if (res && "data" in res) {
            setOpenModal(true);
          }
        })
        .catch((err) => {
          setOpenModal(true);
          console.error(err);
        });
      setForm(supportMessageInitialState);
    }
  };
  return (
    <section className={supportStyles.support}>
      {openModal && (
        <Modal onClose={() => setOpenModal(false)}>
          <SupportSuccessPopup onClose={() => setOpenModal(false)} />
        </Modal>
      )}

      <h2
        className={`${supportStyles.support__title} ${supportStyles.support__title_main}`}
      >
        {title}
      </h2>
      <h3 className={supportStyles.support__title}>פתיחת פנייה ל{title}</h3>
      <p className={supportStyles.support__text}>
        גישה רק לדברים החיוניים ביותר. שדרג לפרימיום וקבל שירותי תמיכה נפשית על
        ידי פסיכולוג מוסמך זמין 24/7, התייעצות בפורום עם מתמודדים כמוך, ועוד
      </p>
      <form className={supportStyles.support__form} onSubmit={submitForm}>
        <label htmlFor="subject" className={supportStyles.support__label}>
          נושא הפנייה
        </label>
        <div
          className={supportStyles.support__inputContainer}
          onClick={(e) => {
            e.stopPropagation();
            setShowThemes(true);
          }}
        >
          <input
            value={form.subject}
            type="text"
            name={"subject"}
            id="subject"
            className={supportStyles.support__input}
            autoFocus={true}
            onChange={handleChange}
          />
          {showThemes && (
            <ul className={supportStyles.support__themes}>
              {techSupportThemes.map((t, index) => (
                <li
                  className={`${supportStyles.support__theme}`}
                  key={index}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowThemes(false);
                    setForm({ ...form, subject: t });
                  }}
                >
                  {t}
                </li>
              ))}
            </ul>
          )}
        </div>
        <label htmlFor="body" className={supportStyles.support__label}>
          תוכן הפנייה
        </label>
        <textarea
          value={form.body}
          name={"body"}
          id="body"
          className={supportStyles.support__textArea}
          onChange={handleChange}
        />
        {(isTechSupportError) && (
          <p className={"error"}>{updateError}</p>
        )}
        <button
          type="submit"
          className={`${supportStyles.support__btn} ${disabled ? supportStyles.support__btn_disabled : ""
            }`}
        >
          שליחת פניה
        </button>
      </form>
    </section>
  );
}

export default SupportApplicationPage;
