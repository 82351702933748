import { BaseQueryApi } from "@reduxjs/toolkit/dist/query";

export const getDefaultHeaders = (
  headers: Headers,
  endpoints: undefined | Pick<BaseQueryApi, "type" | "getState" | "extra" | "endpoint" | "forced">,
  accessToken?: string | undefined
) => {
  headers.set("Accept", "application/json;charset=UTF-8");
  if (!headers.has("Content-Type") && endpoints?.endpoint !== 'uploadFile') {
    headers.set("Content-Type", "application/json");
  }
  if (accessToken) {
    headers.set("Authorization", `Bearer ${accessToken}`);
  }
  return headers;
};
