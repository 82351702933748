import styles from "./document.module.css";
import StatusButton from "../statusButton/statusButton";
import {
  getPrintDate,
  getTimeFromTimestamp,
  parseQuestionAnswerString,
} from "../../utils/utils";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  useTakeInquiryMutation,
  useLazyGetMainPageInfoQuery,
  usePostInquiryUpdateMutation,
} from "../../services/api/user.api";
import { useNavigate } from "react-router-dom";
import {
  TParsedComment,
  TTherapistWithUsers,
  TUsersWithOutTherapist,
} from "../../services/types/user.types";
import { useAppSelector } from "../../services/hooks";
import {
  questionnairWithUserButtonsSelector,
  therapistIdSelector,
  UserQuestionnairSiteLinkSelector,
  userSelector,
} from "../../services/selectors/user.selectors";
import Modal from "../modals/modal/Modal";
import ErrorPopup from "../modals/errorPopup/errorPopup";
import GeneralSuccessPopup from "../modals/generalSuccessPopup/generalSuccessPopup";
import ReturnInquiryConfirmationPopup from "../modals/returnInquiryConfirmation/returnInquiryConfirmationPopup";
import { noDataLabel } from "../../utils/constants";
import useAutosizeTextArea from "../../hooks/useAutosizeTextarea";
import AppointmentButton from "../appointmentButton/AppointmentButton";
import { TQuestionnaireButton } from "../../types/types";

interface IDocumentProps {
  document: TTherapistWithUsers | TUsersWithOutTherapist;
  openDocument: boolean;
  onClose: () => void;
}
function Document({ document, openDocument, onClose }: IDocumentProps) {
  console.log("document: ", document);

  const navigate = useNavigate();
  const therapist = useAppSelector(userSelector);
  const therapistsId = useAppSelector(therapistIdSelector);
  const questionnairButtons = useAppSelector(questionnairWithUserButtonsSelector)
  const questionnairSiteLink = useAppSelector(UserQuestionnairSiteLinkSelector)

  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [takeInquiryServer, isLoading] = useTakeInquiryMutation();
  const [postInquiryUpdate] = usePostInquiryUpdateMutation();
  const [refetchMainPageInfo] = useLazyGetMainPageInfoQuery();

  const [updates, setUpdates] = useState<TParsedComment[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [textAreaValue, setTextAreaValue] = useState("");

  const isTTherapistWithUsers = (
    document: TTherapistWithUsers | TUsersWithOutTherapist
  ): document is TTherapistWithUsers => {
    return "TherapistsId" in document;
  };
  const answers: Record<string, string> | undefined = parseQuestionAnswerString(
    document.SpecificFields
  );
  const isMine =
    isTTherapistWithUsers(document) &&
    document?.TherapistsId.toString() === therapist.id.toString();

  useAutosizeTextArea(textAreaRef.current, textAreaValue);

  useEffect(() => {
    if (document.Comments) {
      const parsedUpdates: TParsedComment[] = JSON.parse(document.Comments);
      if (parsedUpdates.length > 0) {
        setUpdates(parsedUpdates);
      }
    } else {
      setUpdates([]);
    }
  }, [document]);

  const onStatusChange = (newStatusId: number) => {
    takeInquiryServer({
      Id: document.TherapistsUsersId ?? 0,
      Cycle: document.Cycle,
      UsersId: document.UsersId.toString(),
      TherapistsId: therapistsId,
      QuestionnaireId: document.UserQuestionnaireId,
      Status: newStatusId,
      QuestionnaireCompaniesId: document.QuestionnaireCompaniesId
    })
      .then((res) => {
        // @ts-ignore
        if ("error" in res && res?.error?.originalStatus !== 200) {
          setOpenModal(true);
        } else {
          setOpenSuccessModal(true);
        }
      })
      .finally(() => {
        refetchMainPageInfo(therapist.id);
      });
  };

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setTextAreaValue(event.target.value);
  };
  const sendUpdate = (update: string) => {
    const creationDate = new Date(
      Date.now() - (new Date().getTimezoneOffset() * 60000)
    ).toISOString();

    postInquiryUpdate({
      Id: "0",
      Cycle: document.Cycle,
      UsersId: document.UsersId.toString(),
      TherapistsId: therapistsId,
      QuestionnaireId: document.UserQuestionnaireId,
      Comment: update,
      CreateOn: creationDate,
    })
      .then(() => {
        setOpenSuccessModal(true);
      })
      .catch(() => {
        setOpenErrorModal(true);
      })
      .finally(() => {
        refetchMainPageInfo(therapist.id);
      });
  };
  const returnInquiry = () => {
    takeInquiryServer({
      Id: document.Num,
      Cycle: document.Cycle,
      UsersId: document.UsersId.toString(),
      TherapistsId: therapistsId,
      QuestionnaireId: document.UserQuestionnaireId,
      Status: -1,
      QuestionnaireCompaniesId: document.QuestionnaireCompaniesId,
    })
      .then((res) => {
        // @ts-ignore
        if ("error" in res && res?.error?.originalStatus !== 200) {
          setOpenModal(true);
        } else {
          setOpenSuccessModal(true);
        }
      })
      .finally(() => {
        refetchMainPageInfo(therapist.id);
      });
  };

  const onQuestionnairButtonClick = (button: TQuestionnaireButton) => {
    if (questionnairSiteLink) {
      const url = new URL(`${questionnairSiteLink}`);
      url.searchParams.append('therapistGuid', String(therapist.guid));
      url.searchParams.append('userGuid', String(document.Guid));
      url.searchParams.append('questionnaireCompaniesTypeId', String(button.questionnaireCompaniesTypeId));
      window.open(url.toString(), "_blank", "noopener,noreferrer");
    }
  }

  return (
    <article
      className={`${styles.document} ${openDocument && styles.document_visible
        }`}
    >
      <button className={styles.documentCloseBtn} onClick={onClose} />
      <h4 className={styles.documentTitle}>פניה מספר {document?.Num}</h4>
      <div className={styles.documentInfo}>
        <p className={styles.documentLabel}>
          {document?.FakeCompanyId ? "קישור:" : "נושא:"}
          {document?.FakeCompanyId ? (
            <a
              className={styles.documentLink}
              href={`https://app.bpreven.com/${document.UsersId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
            </a>
          ) : (
            <span className={styles.documentText}>
              {document?.QuestionnaireCompaniesType || noDataLabel}
            </span>
          )}
        </p>

        <p className={styles.documentLabel}>
          תאריך פתיחה:
          <span className={styles.documentText}>
            {document?.Timestamp && getPrintDate(new Date(document?.Timestamp))}
          </span>
        </p>
        <p className={styles.documentLabel}>
          מחזור:<span className={styles.documentText}>{document?.Cycle}</span>
        </p>
        <p className={styles.documentLabel}>
          זמן פתיחה:
          <span className={styles.documentText}>
            {document?.Timestamp && getTimeFromTimestamp(document.Timestamp)}
          </span>
        </p>
        <p className={styles.documentLabel}>
          דחיפות:<span className={styles.documentText}>בינוני</span>
        </p>
        <p className={styles.documentLabel}>
          דרך התקשרות:
          <span className={styles.documentText}>
            {(answers && answers["דרך התקשרות"]) || noDataLabel}
          </span>
        </p>
        <p className={styles.documentLabel}>
          מגזר:
          <span className={styles.documentText}>
            {(answers && answers["מגזר"]) || noDataLabel}
          </span>
        </p>
        <p className={styles.documentLabel}>
          מגדר:
          <span className={styles.documentText}>
            {(answers && answers["מגדר"]) || noDataLabel}
          </span>
        </p>
      </div>
      <div className={styles.documentStatusLabel}>
        סטטוס פניה:
        <StatusButton
          document={document}
          onStatusChange={onStatusChange}
          updatable={isMine}
        />
      </div>


      <div className={styles.documentLinks}>
        <button
          onClick={() =>
            navigate(`/users/questions_reports`, {
              state: {
                UserDetails: {
                  UsersId: document.UsersId,
                  Birth: document.Birth,
                  FirstName: document.FirstName,
                  LastName: document.LastName,
                  Phone: document.Phone
                },
                TherapistDetails: {
                  TherapistQuestionnaireId: document.TherapistQuestionnaireId,
                  QuestionnaireCompaniesType: document.QuestionnaireCompaniesType,
                  QuestionnaireCompaniesId: document.QuestionnaireCompaniesId,
                  TherapistCycle: document?.Cycle,
                },
              }
            })
          }
          className={`button button_white ${styles.btn} ${styles.btn_type_actionReport
            } ${!isMine && styles.btn_disabled}`}
          disabled={!isMine}
        >
          דוחות שאלונים
        </button>
        <button
          disabled={!isMine || Boolean(document.TherapistActionId)}
          onClick={() => {
            if ("TherapistsId" in document) {
              navigate(`/users/report/`, {
                state: {
                  TherapistDetails: {
                    TherapistQuestionnaireId: document.TherapistQuestionnaireId,
                    QuestionnaireCompaniesType: document.QuestionnaireCompaniesType,
                    QuestionnaireCompaniesId: document.QuestionnaireCompaniesId,
                    TherapistCycle: document?.Cycle,
                  },
                  UserDetails: {
                    FirstName: document.FirstName,
                    LastName: document.LastName,
                    Phone: document.Phone,
                    Birth: document.Birth,
                    userDocument: document,
                    UsersId: document.UsersId,
                  },
                }
              })
            }
          }
          }
          className={`button button_white ${styles.btn} ${styles.btn_type_actionReport
            } ${(!isMine || Boolean(document.TherapistActionId)) && styles.btn_disabled}`}
        >
          דוח פעולה
        </button>
      </div>
      <h5 className={styles.documentSubTitle}>פרטי התקשרות</h5>
      <p className={styles.documentLabel}>
        מספר משתמש:
        <span className={styles.documentText}>{document?.UsersId}</span>
      </p>
      <p className={styles.documentLabel}>
        שם משתמש:
        <span className={styles.documentText}>
          {document?.FirstName || ""} {document?.LastName || ""}
        </span>
      </p>
      {isMine && (
        <>
          <p className={styles.documentLabel}>
            נייד:
            <span className={styles.documentText}>{document?.Phone}</span>
          </p>

          <p className={styles.documentLabel}>
            אימייל:
            <span className={styles.documentText}>{document?.Email}</span>
          </p>
        </>
      )}
      {isMine &&
        <div className={styles.documentLinks}>
          <AppointmentButton
            userId={document.UsersId.toString()}
            appointmentsId={document.AppointmentsId}
            media={"1"}
            name={"הזמן צ'אט"}
            disabled={!isMine}
            Num={document.Num}
          />
          <AppointmentButton
            userId={document.UsersId.toString()}
            appointmentsId={document.AppointmentsId}
            media={"2"}
            name={"הזמן וידאו"}
            disabled={!isMine}
            Num={document.Num}
          />
        </div>
      }
      <h5 className={styles.documentSubTitle}>הערות</h5>
      {updates && updates.length > 0 ? (
        <ul className={styles.documentUpdates}>
          {updates
            .sort((a: TParsedComment, b: TParsedComment) => b.Id - a.Id)
            .map((upd) => (
              <li key={upd.Id}>
                <div className={styles.updateInfo}>
                  <p className={styles.documentLabel}>
                    עדכון מס':
                    <span className={styles.documentText}>{upd.Id}</span>
                  </p>
                  <p className={styles.documentLabel}>
                    תאריך:
                    <span className={styles.documentText}>
                      {(upd.CreateOn &&
                        `${getPrintDate(
                          new Date(upd.CreateOn)
                        )} ${getTimeFromTimestamp(new Date(upd.CreateOn))}`) ||
                        noDataLabel}
                    </span>
                  </p>
                </div>
                <p className={styles.documentContent}>{upd.Comment}</p>
                <p className={styles.documentLabel}>
                  עודכן על ידי: {upd.TherapistName}
                </p>
              </li>
            ))}
        </ul>
      ) : (
        <p className={styles.noDocuments}>טרם עודכן</p>
      )}
      <div className={styles.textAreaContainer}>
        <textarea
          ref={textAreaRef}
          rows={1}
          name={"updates"}
          placeholder={"שלח עדכון"}
          className={`${styles.documentTextArea}`}
          onChange={handleChange}
          value={textAreaValue}
        />
        <button
          className={styles.sendUpdate}
          disabled={!textAreaValue}
          onClick={() => {
            setTextAreaValue("");
            sendUpdate(textAreaValue);
          }}
        />
      </div>
      {!isMine ? (
        <button
          disabled={isMine || Boolean(isLoading?.isLoading)}
          className={`button button_white ${styles.btn} ${isMine && styles.btn_disabled
            } hover`}
          onClick={(e) => {
            e.stopPropagation();
            takeInquiryServer({
              Id: 0,
              Cycle: document.Cycle,
              UsersId: document.UsersId.toString(),
              TherapistsId: therapistsId,
              QuestionnaireId: document.UserQuestionnaireId,
              Status: document?.Status || 1,
              QuestionnaireCompaniesId: document.QuestionnaireCompaniesId
            })
              .then((res) => {
                // @ts-ignore
                if ("error" in res && res?.error?.originalStatus !== 200) {
                  setOpenModal(true);
                } else {
                  setOpenSuccessModal(true);
                }
              })
              .finally(() => {
                refetchMainPageInfo(therapist.id);
              });
          }}
        >
          לקחת
        </button>
      ) : (
        <button
          className={`button button_white ${styles.btn} ${styles.btn_red}`}
          onClick={() => setOpenConfirmationModal(true)}
        >
          לבטל
        </button>
      )}
      <div className={`${styles.questionnairButton_container}`}>
        {questionnairButtons && questionnairButtons.length !== 0 &&
          questionnairButtons.map((button, index) =>
            <button
              key={index}
              // className={`${styles.questionnairButton_btn} hover`}
              className={`button button_white ${styles.btn} hover`}
              type="button"
              onClick={() => onQuestionnairButtonClick(button)}>
              {button.buttonText}
            </button>
          )
        }
      </div>
      {openModal && (
        <Modal
          onClose={() => {
            setOpenModal(false);
          }}
        >
          <ErrorPopup
            onClose={() => {
              setOpenModal(false);
            }}
          />
        </Modal>
      )}
      {openSuccessModal && (
        <Modal
          onClose={() => {
            setOpenSuccessModal(false);
          }}
        >
          <GeneralSuccessPopup
            onClose={() => {
              setOpenSuccessModal(false);
            }}
          />
        </Modal>
      )}
      {openErrorModal && (
        <Modal
          onClose={() => {
            setOpenErrorModal(false);
          }}
        >
          <ErrorPopup
            onClose={() => {
              setOpenErrorModal(false);
            }}
          />
        </Modal>
      )}
      {openConfirmationModal && (
        <Modal
          onClose={() => {
            setOpenConfirmationModal(false);
          }}
        >
          <ReturnInquiryConfirmationPopup
            onClose={() => {
              returnInquiry();
              setOpenConfirmationModal(false);
            }}
          />
        </Modal>
      )}
    </article>
  );
}

export default Document;
