import popupStyles from "../generalSuccessPopup/generalSuccessPopup.module.css";
import doctor from "../../../images/questionnaire_doctor.png";
import { IPopupWithoutProps } from "../../../types/types";
import { useEffect, useState } from "react";
import { formatCountdown, WARNING_DURATION } from "../../../utils/constants";

function InactivityWarningPopup({ onClose }: IPopupWithoutProps) {
  const [countdown, setCountdown] = useState(WARNING_DURATION / 1000);
  useEffect(() => {
    let countdownInterval: NodeJS.Timeout;
    countdownInterval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          clearInterval(countdownInterval);
          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1000);

    return () => {
      clearInterval(countdownInterval);
    };
  }, []);
  return (
    <article className={popupStyles.popup}>
      <img
        src={doctor}
        alt="תמונה של וונצ’י"
        className={popupStyles.popup__img}
      />
      <p
        className={`${popupStyles.popup__title} ${popupStyles.popup__title_small}`}
      >
        שמתי לב כי לא השתמשת באפליקציה שלנו זמן רב.
      </p>
      <p className={popupStyles.popup__text}>
        למען אבטחת המידע שלנו ושמירה על פרטיותך, החלטנו לבצע יציאה אוטומטית
        מהמערכת. המערכת תבצע את היציאה בעוד{" "}
        <span>{formatCountdown(countdown)}</span>
      </p>
      <button
        className={`button hover ${popupStyles.popup__nextBtn}`}
        onClick={onClose}
      >
        תשאיר אותי כאן
      </button>
    </article>
  );
}

export default InactivityWarningPopup;
