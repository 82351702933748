import popupStyles from "./appointmentCard.module.css";
import { getDuration } from "../../../utils/utils";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import {
  TCalendarAppointment,
  TUpdateChatAppointmentEntry,
} from "../../../services/types/user.types";
import {
  useLazySendSMSToChatQuery,
  useUpdateChatAppointmentMutation,
} from "../../../services/api/user.api";
import { formatDateTime } from "../../../utils/constants";
import Modal from "../modal/Modal";
import ErrorPopup from "../errorPopup/errorPopup";

interface IAppointmentCardProps {
  appointmentData: TCalendarAppointment;
}
function AppointmentCard({ appointmentData }: IAppointmentCardProps) {
  const navigate = useNavigate();
  console.log(appointmentData);

  const [openErrorModal, setOpenErrorModal] = useState(false);

  const [updateChatAppointment] = useUpdateChatAppointmentMutation();
  const [sendSMSToChat] = useLazySendSMSToChatQuery();

  const startAppointment = () => {
    const body: TUpdateChatAppointmentEntry[] = [
      {
        op: "replace",
        path: "/startTime",
        value: formatDateTime(new Date()).split("T")[1].replace("Z", ""),
      },
      { op: "replace", path: "/Active", value: 1 },
    ];
    updateChatAppointment({ body, appointmentId: appointmentData.id }).then(
      (res) => {
        if ("data" in res && res.data) {
          sendSMSToChat({
            AppointmentId: appointmentData.id,
            UsersId: appointmentData.usersId,
          });
          navigate(`/messages`, {
            state: { pathname: appointmentData.users?.id },
          });
        } else {
          setOpenErrorModal(true);
        }
      }
    );
  };

  return (
    <article className={popupStyles.popup}>
      <h2 className={popupStyles.popup__title}>פרטי פגישה</h2>
      <p className={popupStyles.popup__text}>שם המטופל</p>
      <p className={popupStyles.popup__text_big}>
        {appointmentData.users?.fullName}
      </p>
      <p className={popupStyles.popup__text}>תאריך פגישה</p>
      <p className={popupStyles.popup__text_big}>
        {`${appointmentData.appointmentDate.getDate()}/${
          appointmentData.appointmentDate.getMonth() + 1
        }/${appointmentData.appointmentDate.getFullYear()}`}
      </p>
      <p className={popupStyles.popup__text}>שעת פגישה</p>
      <p className={popupStyles.popup__text_big}>
        {appointmentData.startTime && appointmentData.endTime
          ? `${appointmentData.startTime} - ${appointmentData.endTime}`
          : getDuration(appointmentData.appointmentDate, 50)}
      </p>
      <p className={popupStyles.popup__text}>אופן הפגישה</p>
      <p className={popupStyles.popup__text_big}>
        {appointmentData.mediaId === 1
          ? "צ'אט"
          : appointmentData.mediaId === 2
          ? "וידאו"
          : "טלפון"}
      </p>
      <button
        type={"button"}
        className={`${popupStyles.popup__btn} ${popupStyles.popup__nextBtn} hover`}
        onClick={startAppointment}
      >
        להתחיל פגישה
      </button>
      <button
        type={"button"}
        className={`${popupStyles.popup__btn} button hover`}
        onClick={() =>
          navigate(`/users/personal`, {
            state: {
              UserDetails: {
                UsersId: appointmentData.users?.id,
                Guid: appointmentData.users?.guid,
              },
            },
          })
        }
      >
        לראות פרטים אישיים
      </button>
      {openErrorModal && (
        <Modal
          onClose={() => {
            setOpenErrorModal(false);
          }}
        >
          <ErrorPopup
            onClose={() => {
              setOpenErrorModal(false);
            }}
          />
        </Modal>
      )}
    </article>
  );
}

export default AppointmentCard;
