import styles from "./userInfo.module.css";
import React from "react";
import {
  TTherapistUser,
  TTherapistWithUsers,
  TUserInfo,
} from "../../services/types/user.types";
import { calculateAge, mobileWidth, noDataLabel } from "../../utils/constants";
import useMediaQuery from "../../hooks/useMediaQuery";

function UserInfo({
  user,
  customClassName,
}: {
  user: TTherapistWithUsers | TTherapistUser | TUserInfo;
  customClassName?: string;
}) {
  const usersId = "UsersId" in user ? user.UsersId : user.Id;
  const mobile = useMediaQuery(mobileWidth);

  return (
    <section className={`${styles.userSection} ${customClassName}`}>
      <article className={styles.user}>
        <div>
          <p className={`${styles.user__label} ${styles.form__subtitle}`}>
            {mobile ? "שם: " : "שם משתמש:"}
          </p>
          <p className={styles.user__text}>
            {user?.FirstName || ""} {user?.LastName || ""}
          </p>
        </div>
        <div>
          <p className={`${styles.user__label} ${styles.form__subtitle}`}>
            מס' משתמש:
          </p>
          <p className={styles.user__text}>{usersId}</p>
        </div>
        <div>
          <p className={`${styles.user__label} ${styles.form__subtitle}`}>
            גיל:
          </p>
          <p className={styles.user__text}>
            {
              user.Birth ?
                calculateAge(user.Birth)
                : "אין מידע"
            }
          </p>
        </div>
        <div>
          <p className={`${styles.user__label} ${styles.form__subtitle}`}>
            נייד:
          </p>
          <p className={styles.user__text}>{user?.Phone || noDataLabel}</p>
        </div>
      </article>
    </section>
  );
}

export default UserInfo;
