import styles from "./editAvailabilityPage.module.css";
import React, { useEffect, useState } from "react";
import Modal from "../../components/modals/modal/Modal";
import EditAvailabilityPopup from "../../components/modals/editAvailabilityPopup/editAvailabilityPopup";
import { useAppSelector } from "../../services/hooks";
import { availabilitySelector, therapistCompanyIdSelector, therapistIdSelector } from "../../services/selectors/user.selectors";
import BackArrowButton from "../../components/backArrowButton/backArrowButton";
import { useNavigate } from "react-router-dom";
import { useGetTherapistAvailabilityMutation } from "../../services/api/user.api";
import { weekdays } from "../../utils/constants";
import { IAvailability } from "../../types/types";

function EditAvailabilityPage() {
  const navigate = useNavigate();
  const availability = useAppSelector(availabilitySelector);

  const [showEditHoursPopup, setShowEditHoursPopup] = useState(false);
  const [dayToEdit, setDayToEdit] = useState<null | IAvailability[]>(null);
  const [currentDayId, setCurrentDayId] = useState<number>(0)
  const setHours = (day: IAvailability[], dayId: number) => {
    setCurrentDayId(dayId)
    setDayToEdit(day);
    setShowEditHoursPopup(true);
  };
  const therapistId = useAppSelector(therapistIdSelector)
  const therapistCompanyId = useAppSelector(therapistCompanyIdSelector)
  const [getAvailability] = useGetTherapistAvailabilityMutation()

  useEffect(() => {
    getAvailability({ CompanyId: therapistCompanyId, TherapistId: Number(therapistId) })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Function to group by dayId
  const groupRangesByDay = (ranges: IAvailability[]) => {
    return ranges.reduce<Record<string, IAvailability[]>>((acc, range) => {
      if (!acc[range.dayId]) {
        acc[range.dayId] = [];
      }
      acc[range.dayId].push(range);
      return acc;
    }, {});
  };

  const groupAvailabiltyByDay = groupRangesByDay(availability.filter(day => !day.specificDate))

  return (
    <section className={styles.section}>
      <BackArrowButton
        text={"לחזור"}
        onClick={() => navigate(-1)}
        position={"center"}
      />

      <h2 className={styles.title}>זמינות שבועית</h2>
      <p className={styles.text}>
        כדי להגדיר שעות זמינות שבועיות פשוט לחץ על יום כדי לערוך
      </p>
      <ul className={styles.days}>
        {Object.keys(weekdays).map((dayId) => {

          const rangesForDay = groupAvailabiltyByDay[dayId] || [];

          return (
            <li
              key={dayId}
              className={styles.daysLi}
              onClick={() => setHours(rangesForDay, Number(dayId))}
            >
              <p className={styles.daysTitle}>{weekdays[dayId].title}</p>
              <div className={styles.daysContent}>
                {(rangesForDay.length && rangesForDay[0].isAvailable) ?
                  (
                    rangesForDay.map((r: IAvailability, index: number) => (
                      <div key={index} className={styles.daysTimeContainer}>
                        <span className={styles.daysAvailableCircle} />
                        <p key={index} className={styles.daysText}>
                          {r.startTime.slice(0, 5)} - {r.endTime.slice(0, 5)}
                        </p>
                      </div>
                    ))
                  ) : (
                    <p
                      className={`${styles.daysText} ${styles.daysText_notAvailable}`}
                    >
                      לא זמין
                    </p>
                  )
                }
              </div>
            </li>
          )
        })}
      </ul>
      <div className={styles.alternative}>או</div>
      <button
        className={`button hover ${styles.alternative__btn}`}
        onClick={() => navigate("monthly")}
      >
        לערוך זמינות חודשית
      </button>
      {showEditHoursPopup && dayToEdit !== null && (
        <Modal
          onClose={() => {
            setShowEditHoursPopup(false);
            setDayToEdit(null);
          }}
        >
          <EditAvailabilityPopup
            day={dayToEdit}
            dayId={currentDayId}
            onClose={() => {
              setShowEditHoursPopup(false);
              setDayToEdit(null);
            }}
          />
        </Modal>
      )}
    </section>
  );
}

export default EditAvailabilityPage;
