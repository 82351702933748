import chatSidebarStyles from "./chatSidebar.module.css";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import defaultAvatar from "../../images/default_avatar.png";
import { useAppSelector } from "../../services/hooks";
import CloseChatButton from "../closeChatButton/closeChatButton";
import { chatUsersSelector } from "../../services/selectors/chat.selector";
import { IUserChatData } from "../../services/types";

interface ChatSideBarProps {
  activeUser: undefined | IUserChatData;
  setActiveUser: Dispatch<SetStateAction<undefined | IUserChatData>>;
  setOpenSideBar?: Dispatch<SetStateAction<boolean>>;
  openMenu: boolean;
}

const ChatSideBar: React.FC<ChatSideBarProps> = ({
  activeUser,
  setActiveUser,
  openMenu,
  setOpenSideBar,
}) => {
  const chatUsers = useAppSelector(chatUsersSelector);
  const [users, setUsers] = useState(chatUsers);
  const [showActiveAppointments, setShowActiveAppointments] = useState(true);

  useEffect(() => {
    if (showActiveAppointments) {
      const activeUsers = chatUsers.filter((u) => u.active);
      setUsers(activeUsers);

      // activeUsers[0] && setActiveUser(activeUsers[0]);
    } else {
      setUsers(chatUsers.filter((u) => !u.active));
    }
  }, [showActiveAppointments, chatUsers, setActiveUser]);

  return (
    <section
      className={`${chatSidebarStyles.sidebar} ${!openMenu && chatSidebarStyles.sidebar_closed
        }`}
    >
      <div className={chatSidebarStyles.sidebar__buttons}>
        <button
          className={`${chatSidebarStyles.sidebar__btn} ${!showActiveAppointments && chatSidebarStyles.sidebar__btn_active
            }`}
          onClick={() => setShowActiveAppointments(false)}
        >
          ארכיון
        </button>
        <button
          className={`${chatSidebarStyles.sidebar__btn} ${showActiveAppointments && chatSidebarStyles.sidebar__btn_active
            }`}
          onClick={() => setShowActiveAppointments(true)}
        >
          שיחות
        </button>
      </div>
      <input
        type="text"
        placeholder="חיפוש"
        className={chatSidebarStyles.sidebar__input}
      />
      {users.length > 0 ? (
        <ul
          className={`${chatSidebarStyles.sidebar__therapists} ${chatSidebarStyles.scrollable} scroll`}
        >
          {users.map((u) => (
            <li
              key={u.userId}
              className={`${chatSidebarStyles.sidebar__li} ${u.userId === activeUser?.userId &&
                chatSidebarStyles.sidebar__li_active
                }`}
              onClick={() => {
                setActiveUser(u);
                if (setOpenSideBar) setOpenSideBar(false);
              }}
            >
              <div
                className={`${chatSidebarStyles.sidebar__online} ${u.online && chatSidebarStyles.sidebar__online_active
                  }`}
              >
                <img
                  className={chatSidebarStyles.sidebar__avatar}
                  src={defaultAvatar}
                  alt={u.fullName}
                />
              </div>
              <div className={chatSidebarStyles.sidebar__therapist}>
                {/*<p className={chatSidebarStyles.sidebar__name}>{u.fullName}</p>*/}
                <p className={chatSidebarStyles.sidebar__name}>
                  {u.fullName} ({u?.appointment?.id})
                </p>
              </div>
              {u.appointment && u.appointment.active && (
                <CloseChatButton appointment={u.appointment} />
              )}
            </li>
          ))}
        </ul>
      ) : (
        <p className={chatSidebarStyles.noAppointmentsText}>טרם קבעת פגישות</p>
      )}
    </section>
  );
};

export default ChatSideBar;
