import styles from "./videoCallWindow.module.css";
import ModalOverlay from "../modalOverlay/ModalOverlay";
import React, { useContext, useEffect, useState } from "react";
import { SocketContext } from "../../../SocketContext";
import incomingCallSound from "../../../sounds/incomingCallSound.wav";
import { toggleMuteMic, toggleVideo } from "../../../utils/utils";
import endCallIcon from "../../../images/end_call_icon.svg";
import Modal from "../modal/Modal";
import MediaDevicesNotFoundErrorPopup from "../mediaDevicesNotFoundErrorPopup/mediaDevicesNotFoundErrorPopup";

interface IVideoCallWindowProps {
  onClose: () => void;
}

function VideoCallWindow({ onClose }: IVideoCallWindowProps) {
  const {
    myVideo,
    stream,
    call,
    callAccepted,
    startCall,
    endCall,
    userVideo,
    anotherUserStream,
    setShowMediaDevicesNotFoundPopup,
  } = useContext(SocketContext);

  const [isMicMuted, setIsMicMuted] = useState(false);
  const [isVideoEnabled, setIsVideoEnabled] = useState(true);
  console.log("Video Tracks:", stream?.getVideoTracks());
  stream?.getVideoTracks().forEach((track) => {
    console.log("Track State:", track.readyState, "Enabled:", track.enabled);
  });

  useEffect(() => {
    if (myVideo.current) {
      myVideo.current.srcObject = stream as MediaProvider;
    }
    if (userVideo.current) {
      userVideo.current.srcObject = anotherUserStream as MediaProvider;
    }
    return () => {
      // Cleanup: Ensure video is enabled when the component unmounts
      if (stream) {
        stream.getVideoTracks().forEach((track) => {
          track.enabled = true; // Re-enable the video track
        });
        setIsVideoEnabled(true); // Reset state for video toggle
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stream]);
  if (!stream)
    return (
      <Modal onClose={() => setShowMediaDevicesNotFoundPopup(false)}>
        <MediaDevicesNotFoundErrorPopup
          onClose={() => setShowMediaDevicesNotFoundPopup(false)}
        />
      </Modal>
    );
  return (
    <>
      <div className={`${styles.window} `}>
        {!callAccepted && <audio src={incomingCallSound} autoPlay={true} />}
        <div className={styles.videoContainer}>
          <h3 className={styles.header}>מתקשר ל{call?.to?.name}</h3>
          {stream && (
            <>
              <video
                playsInline
                // @ts-ignore
                ref={myVideo}
                muted
                autoPlay
                className={styles.myVideo}
              />
              <div className={styles.userVideoWindow}>
                {startCall && !callAccepted && (
                  <div className={styles.outgoingCall}>
                    <div className={styles.pulse} />
                  </div>
                )}
                {callAccepted && (
                  <video
                    playsInline
                    // muted
                    // @ts-ignore
                    ref={userVideo}
                    autoPlay
                    className={styles.userVideo}
                  />
                )}
                <div className={styles.buttonsBar}>
                  <button
                    type={"button"}
                    className={`hover ${styles.btn} `}
                    onClick={() => {
                      toggleMuteMic(stream, setIsMicMuted);
                    }}
                  >
                    <span
                      className={`${styles.btn_type_mute} ${
                        isMicMuted && styles.btn_type_unmute
                      }`}
                    />
                  </button>
                  <button
                    type={"button"}
                    className={`hover ${styles.btn} `}
                    onClick={() => {
                      toggleVideo(stream, setIsVideoEnabled);
                    }}
                  >
                    <span
                      className={`${styles.btn_type_mute} ${
                        styles.btn_type_video
                      } ${!isVideoEnabled && styles.btn_type_video_inactive}`}
                    />
                  </button>
                  <button
                    type={"button"}
                    className={`hover ${styles.btn}`}
                    onClick={() => {
                      endCall();
                    }}
                  >
                    <img
                      alt="לחצן סיום שיחה"
                      className={styles.btn__image}
                      src={endCallIcon}
                    />
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <ModalOverlay onClick={onClose} />
    </>
  );
}

export default VideoCallWindow;
