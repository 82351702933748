import changeAvatarPopup from "./changeAvatarPopup.module.css";
import React, { useState } from "react";
import { IPopupWithoutProps } from "../../../types/types";
import ChangePasswordSuccessPopup from "../changePasswordSuccessPopup/changePasswordSuccessPopup";
import { useFormik } from "formik";
import { changeAvatarValidationSchema } from "../../../utils/validation";
import loginStyles from "../../../pages/login/login.module.css";
import {
  useLazyGetUserQuery,
  useUpdateUserMutation,
  useUploadFileMutation,
} from "../../../services/api/user.api";
import { useAppSelector } from "../../../services/hooks";
import { errorUserSelector } from "../../../services/selectors/auth.selectors";
import { userSelector } from "../../../services/selectors/user.selectors";
import Modal from "../modal/Modal";
import ErrorPopup from "../errorPopup/errorPopup";
import uploadIcon from "../../../images/upload_icon.svg"

function ChangeAvatarPopup({ onClose }: IPopupWithoutProps) {

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [image, setImage] = useState<File | null>(null);
  const [imageURL, setImageURL] = useState<string | null>(null);

  const [updateUser, { isError: isErrorUpdating }] = useUpdateUserMutation();
  const [uploadImage, { isError }] = useUploadFileMutation();
  const [getUser] = useLazyGetUserQuery();

  const user = useAppSelector(userSelector);
  const updateError = useAppSelector(errorUserSelector);


  const handleSubmit = async () => {
    if (image) {
      const formData = new FormData();
      formData.append("myfile", image);
      const response = await uploadImage({
        form: formData,
        usersId: user.id,
        type: 1
      });
      if ("data" in response && response.data) {
        setOpenModal(true);
        getUser();
      } else {
        setOpenErrorModal(true)
      }
    } else if (formik.values.link) {
      updateUser({
        body:
          [
            {
              op: "replace",
              path: "/avatar",
              value: formik.values.link
            }
          ],
        id: user.id,
      }).then(response => {
        if ("data" in response && response.data) {
          setOpenModal(true);
          getUser();
        }
      }).catch(error => {
        console.error(error);
        setOpenErrorModal(true)
      })
    }
  }

  const handleImageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setImage(file);
      formik.setFieldValue("avatar", file);
      formik.setFieldValue("avatarName", file.name);
      const url = URL.createObjectURL(file); // Create a URL for the file
      setImageURL(url);
    }
  };

  const formik = useFormik({
    initialValues: {
      avatar: "",
      avatarName: "",
      link: ""
    },
    validationSchema: changeAvatarValidationSchema,
    onSubmit: () => {
      handleSubmit();
    },
  });


  return openErrorModal ?
    (<Modal onClose={() => setOpenErrorModal(false)}>
      <ErrorPopup onClose={() => setOpenErrorModal(false)} />
    </Modal>) :
    openModal ? (
      <ChangePasswordSuccessPopup onClose={onClose} type={"avatar"} />
    ) : (
      <article className={changeAvatarPopup.popup}>
        <div className={changeAvatarPopup.popup__content}>
          <h2 className={changeAvatarPopup.popup__title}>
            שינוי תמונת פרופיל
          </h2>
          <form
            id="updateAvatar"
            onSubmit={formik.handleSubmit}
            className={changeAvatarPopup.popup__form}
          >

            <label
              htmlFor={"link"}
              className={changeAvatarPopup.popup__link_label}
            >
              הזן קישור לתמונת הפרופיל שלך
            </label>
            <input
              type="text"
              name={"link"}
              id={"link"}
              placeholder={"הזן קישור"}
              value={formik.values.link}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`${changeAvatarPopup.popup__link_input} ${formik.errors.link &&
                changeAvatarPopup.popup__input_invalid
                }`}
            />
            <p>או-</p>

            <div className={changeAvatarPopup.file_input_wrapper}>
              {(formik.values.avatar && imageURL) && (
                <img
                  src={imageURL}
                  alt="Avatar Preview"
                  className={`w-full h-full object-cover rounded-full border border-gray-300 ${changeAvatarPopup.popup__img}`}
                />
              )}
              <label
                htmlFor={"avatar"}
                // className={changePasswordPopupStyles.popup__label}
                // className="px-4 py-2 bg-purple-600 text-white text-sm font-medium rounded-lg cursor-pointer hover:bg-purple-700 focus:ring-2 focus:ring-purple-500 focus:outline-none"
                className={changeAvatarPopup.popup__label}
              >
                <img
                  src={uploadIcon}
                  alt="העלה לכאן תמונה"
                  className={changeAvatarPopup.popup__img_upload}
                />
                <p>בחר תמונה</p>
              </label>
              <input
                type="file"
                name={"avatar"}
                id={"avatar"}
                placeholder={"הזן קישור"}
                // value={formik.values.avatar}
                accept="image/*"
                onChange={(e) => handleImageChange(e)}
                onBlur={formik.handleBlur}
                className={changeAvatarPopup.popup__input_hiden}

              />
            </div>
            {(formik.errors.avatar || formik.errors.link) && (
              <p className={`${loginStyles.login__invalid_message}`}>
                {formik.errors.avatar ? formik.errors.avatar : formik.errors.link}
              </p>
            )}
            {(isError || isErrorUpdating) && (
              <p className={`${loginStyles.login__invalid_message}`}>
                {updateError}
              </p>
            )}
            <button
              type="submit"
              form="updateAvatar"
              disabled={!formik.isValid || formik.isSubmitting}
              className={`button hover ${changeAvatarPopup.popup__submitBtn
                } ${(!formik.isValid || formik.isSubmitting) &&
                changeAvatarPopup.popup__submitBtn_disabled
                }`}
            >
              לעדכון התמונה
            </button>
          </form>
        </div>
      </article>
    );
}

export default ChangeAvatarPopup;
