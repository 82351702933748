import popupStyles from "../errorPopup/errorPopup.module.css";
import mediaDevicesErrorPopupStyles from "../mediaDevicesErrorPopup/mediaDevicesErrorPopup.module.css";
import doctor from "../../../images/questionnaire_doctor.png";
import { IPopupWithoutProps } from "../../../types/types";

function MediaDevicesNotFoundErrorPopup({ onClose }: IPopupWithoutProps) {
  return (
    <article className={popupStyles.popup}>
      <h2
        className={`${popupStyles.popup__title} ${mediaDevicesErrorPopupStyles.popup__title}`}
      >
        המצלמה או המיקרופון לא נמצאו או אינם פועלים.המטפל מנסה להתקשר אליכם.
      </h2>
      <img
        src={doctor}
        alt="תמונה של וונצ’י"
        className={`${popupStyles.popup__img} ${mediaDevicesErrorPopupStyles.popup__img}`}
      />
      <p className={mediaDevicesErrorPopupStyles.popup__text}>
        אנא בדקו את ההגדרות או את החיבורים שלכם, ואז רעננו את הדף.
      </p>
      <button
        className={`button hover ${popupStyles.popup__nextBtn}`}
        onClick={onClose}
      >
        אישור
      </button>
    </article>
  );
}

export default MediaDevicesNotFoundErrorPopup;
