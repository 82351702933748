import signinFormStyles from "../signin/signinForms.module.css";
import { useFormik } from "formik";
import { addNewUserValidationSchema } from "../../../utils/validation";
import React from "react";
import { useGetAllGendersQuery } from "../../../services/api/user.api";
import Loader from "../../loader/loader";
import { TUserForm } from "../../../types/types";

function AddNewUserForm({
  onSubmit,
}: {
  onSubmit: (values: TUserForm) => void;
}) {
  const { data: genderOptions, isLoading } = useGetAllGendersQuery();

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      birth: "",
      genderId: 3,
      passport: "",
    },
    validationSchema: addNewUserValidationSchema,
    onSubmit: (values, { resetForm }) => {
      // Handle form submission
      onSubmit(values);
      resetForm();
    },
  });

  return isLoading ? (
    <Loader />
  ) : (
    <form
      className={signinFormStyles.signin_private__form}
      onSubmit={formik.handleSubmit}
    >
      <div className={signinFormStyles.signin_private__questionsContainer}>
        <div className={signinFormStyles.signin_private__formField}>
          <label
            htmlFor="firstName"
            className={signinFormStyles.signin_private__label}
          >
            שם פרטי
          </label>
          <input
            type="text"
            className={`${signinFormStyles.signin_private__input} ${
              formik.errors.firstName &&
              signinFormStyles.signin_private__input_invalid
            }`}
            name="firstName"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="שם פרטי"
          />
        </div>
        <div className={signinFormStyles.signin_private__formField}>
          <label
            htmlFor="lastName"
            className={signinFormStyles.signin_private__label}
          >
            שם משפחה
          </label>
          <input
            type="text"
            className={`${signinFormStyles.signin_private__input} ${
              formik.errors.lastName &&
              signinFormStyles.signin_private__input_invalid
            }`}
            name="lastName"
            id="lastName"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="שם משפחה"
          />
        </div>
        <div className={signinFormStyles.signin_private__formField}>
          <label
            htmlFor="email"
            className={signinFormStyles.signin_private__label}
          >
            אימייל
          </label>
          <input
            type="string"
            className={`${signinFormStyles.signin_private__input} ${
              formik.errors.email &&
              signinFormStyles.signin_private__input_invalid
            }`}
            name="email"
            id="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="אימייל"
          />
        </div>{" "}
        <div className={signinFormStyles.signin_private__formField}>
          <label
            htmlFor="passport"
            className={signinFormStyles.signin_private__label}
          >
            תעודת זהות
          </label>
          <input
            type="string"
            className={`${signinFormStyles.signin_private__input} ${
              formik.errors.passport &&
              signinFormStyles.signin_private__input_invalid
            }`}
            name="passport"
            id="passport"
            value={formik.values.passport}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="תעודת זהות"
          />
        </div>
        <div className={signinFormStyles.signin_private__formField}>
          <label
            htmlFor="birth"
            className={signinFormStyles.signin_private__label}
          >
            תאריך לידה
          </label>
          <input
            type="date"
            className={`${signinFormStyles.signin_private__input} ${
              formik.errors.birth &&
              signinFormStyles.signin_private__input_invalid
            }`}
            name="birth"
            id="birth"
            value={formik.values.birth}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="שם פרטי"
            autoFocus={true}
          />
        </div>
        <div className={signinFormStyles.signin_private__formField}>
          <label
            htmlFor="phone"
            className={signinFormStyles.signin_private__label}
          >
            טלפון נייד
          </label>
          <input
            type="text"
            className={`${signinFormStyles.signin_private__input} ${
              formik.errors.phone &&
              signinFormStyles.signin_private__input_invalid
            }`}
            name="phone"
            value={
              formik.values.phone
                ? formik.values.phone.replace(/[^\d]+/g, "")
                : ""
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="טלפון נייד"
          />
        </div>
        <div className={signinFormStyles.signin_private__formField}>
          <label
            htmlFor="gender"
            className={signinFormStyles.signin_private__label}
          >
            מגדר
          </label>
          {genderOptions && (
            <select
              name={"genderId"}
              value={formik.values.genderId}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`${signinFormStyles.signin_private__input}`}
            >
              {genderOptions.map((o) => (
                <option key={o.id} value={o.id}>
                  {o.name}
                </option>
              ))}
            </select>
          )}
        </div>
      </div>

      {formik.errors && (
        <p className={`${signinFormStyles.signin_private__invalid_message}`}>
          {formik.errors.firstName ??
            formik.errors.lastName ??
            formik.errors.email ??
            formik.errors.passport ??
            formik.errors.birth ??
            formik.errors.phone}
        </p>
      )}
      <button
        type="submit"
        disabled={!formik.isValid || formik.isSubmitting}
        className={`${signinFormStyles.signin_private__btn} ${
          !formik.isValid || formik.isSubmitting
            ? signinFormStyles.signin_private__btn_disabled
            : "hover"
        }`}
      >
        המשך
      </button>
    </form>
  );
}

export default AddNewUserForm;
