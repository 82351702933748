export interface IInquiryUpdate {
  id: string;
  date: Date;
  text: string;
}
export interface IInquiry {
  id: string;
  issued: Date;
  inquiry: string;
  category: string;
  status: string;
  belongsTo: string | null;
  urgent: boolean;
  userId: string;
  updates: IInquiryUpdate[];
}

export const inquiries: IInquiry[] = [
  {
    id: "1",
    issued: new Date(),
    category: "פניה ראשונה",
    inquiry:
      "לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית ושבעגט ליבם סולגק.",
    status: "inProgress",
    belongsTo: "1",
    urgent: true,
    userId: "5",
    updates: [
      {
        id: "1",
        date: new Date(),
        text: "בראיט ולחת צורק מונחף, בגורמי מגמש. תרבנך וסתעד לכנו סתשם השמה.",
      },
    ],
  },
  {
    id: "2",
    issued: new Date(),
    category: "פניה ראשונה",
    inquiry:
      "בראיט ולחת צורק מונחף, בגורמי מגמש. תרבנך וסתעד לכנו סתשם השמה - לתכי מורגם בורק?",
    status: "notYetAddressed",
    belongsTo: "1",
    urgent: true,
    userId: "2",
    updates: [],
  },
  {
    id: "3",
    issued: new Date(),
    category: "פניה ראשונה",
    inquiry:
      "נולום ארווס סאפיאן - פוסיליס קוויס, אקווזמן קולהע צופעט למרקוח איבן איף, ברומץ כלרשט מיחוצים. קלאצי קולהע צופעט למרקוח איבן איף, ברומץ כלרשט מיחוצים.",
    status: "notYetAddressed",
    belongsTo: null,
    urgent: true,
    userId: "3",
    updates: [],
  },
  {
    id: "4",
    issued: new Date(),
    category: "פניה ראשונה",
    inquiry:
      "להאמית קרהשק סכעיט דז מא, מנכם למטכין נשואי מנורךגולר מונפרר סוברט לורם שבצק יהול, לכנוץ בעריר גק ליץ, ושבעגט. נולום ארווס סאפיאן - פוסיליס קוויס, אקווזמן קוואזי במר מודוף. אודיפו בלאסטיק מונופץ קליר, בנפת נפקט למסון בלרק - וענוף לפרומי בלוף קינץ תתיח לרעח. לת צשחמי להאמית קרהשק סכעיט דז מא, מנכם למטכין נשואי מנורך.",
    status: "notYetAddressed",
    belongsTo: null,
    urgent: true,
    userId: "4",
    updates: [],
  },
  {
    id: "5",
    issued: new Date(),
    category: "פניה ראשונה",
    inquiry:
      "להאמית קרהשק סכעיט דז מא, מנכם למטכין נשואי מנורךגולר מונפרר סוברט לורם שבצק יהול, לכנוץ בעריר גק ליץ, ושבעגט. נולום ארווס סאפיאן - פוסיליס קוויס, אקווזמן קוואזי במר מודוף. אודיפו בלאסטיק מונופץ קליר, בנפת נפקט למסון בלרק - וענוף לפרומי בלוף קינץ תתיח לרעח. לת צשחמי להאמית קרהשק סכעיט דז מא, מנכם למטכין נשואי מנורך.",
    status: "notYetAddressed",
    belongsTo: null,
    urgent: true,
    userId: "6",
    updates: [],
  },
  {
    id: "6",
    issued: new Date(),
    category: "פניה ראשונה",
    inquiry:
      "להאמית קרהשק סכעיט דז מא, מנכם למטכין נשואי מנורךגולר מונפרר סוברט לורם שבצק יהול, לכנוץ בעריר גק ליץ, ושבעגט. נולום ארווס סאפיאן - פוסיליס קוויס, אקווזמן קוואזי במר מודוף. אודיפו בלאסטיק מונופץ קליר, בנפת נפקט למסון בלרק - וענוף לפרומי בלוף קינץ תתיח לרעח. לת צשחמי להאמית קרהשק סכעיט דז מא, מנכם למטכין נשואי מנורך.",
    status: "notYetAddressed",
    belongsTo: null,
    urgent: false,
    userId: "7",
    updates: [],
  },
  {
    id: "7",
    issued: new Date(),
    category: "פניה ראשונה",
    inquiry:
      "להאמית קרהשק סכעיט דז מא, מנכם למטכין נשואי מנורךגולר מונפרר סוברט לורם שבצק יהול, לכנוץ בעריר גק ליץ, ושבעגט. נולום ארווס סאפיאן - פוסיליס קוויס, אקווזמן קוואזי במר מודוף. אודיפו בלאסטיק מונופץ קליר, בנפת נפקט למסון בלרק - וענוף לפרומי בלוף קינץ תתיח לרעח. לת צשחמי להאמית קרהשק סכעיט דז מא, מנכם למטכין נשואי מנורך.",
    status: "notYetAddressed",
    belongsTo: null,
    urgent: false,
    userId: "8",
    updates: [],
  },
];
