// src/slices/chatSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HubConnection } from "@microsoft/signalr";
import { IChatMessage, IUserChatData } from "../types";
import { IUser } from "../../types/types";
import { userApi } from "../api/user.api";
import { IChatAppointment } from "../types/user.types";
import { noDataLabel } from "../../utils/constants";

interface IChatState {
  isConnected: boolean;
  connection?: HubConnection;
  chatUsers: IUserChatData[];
  allConnectedUsers: number[];
  showUserJoinedModal: undefined | IUser;
  callEnded: undefined | string;
}

const initialState: IChatState = {
  isConnected: false,
  chatUsers: [],
  allConnectedUsers: [],
  callEnded: undefined,
  showUserJoinedModal: undefined,
};

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setWsConnection(state, action: PayloadAction<HubConnection>) {
      state.connection = action.payload;
    },
    setConnected(state, action: PayloadAction<boolean>) {
      state.isConnected = action.payload;
    },
    setChatUsers(state, { payload }: { payload: IUserChatData[] }) {
      state.chatUsers = payload;
    },
    endCall(state, { payload }: { payload: string | undefined }) {
      return {
        ...state,
        callEnded: payload,
      };
    },
    updateChatData(
      state,
      {
        payload,
      }: { payload: [{ online: boolean; userId: number; messages?: [] }] }
    ) {
      return {
        ...state,
        chatUsers: state.chatUsers.map((u) => {
          const user = payload.find((us) => us.userId === u.userId);
          return {
            ...u,
            online: !!user?.online,
            messages: user?.messages || u.messages,
          };
        }),
      };
    },
    addMessage(state, { payload }: { payload: IChatMessage }) {
      state.chatUsers = state.chatUsers.map((u) =>
        u.userId === payload.senderId || u.userId === payload.receiverId
          ? { ...u, messages: [...u.messages, payload] }
          : u
      );
    },
    setUserTyping(state, { payload }: { payload: IChatMessage }) {
      return {
        ...state,
        chatUsers: state.chatUsers.map((usr) =>
          usr.userId === payload.senderId
            ? {
                ...usr,
                isTyping: payload.content.split(":")[1] === "1",
              }
            : usr
        ),
      };
    },
    setUserConnected(state, { payload }: { payload: number }) {
      return {
        ...state,
        chatUsers: state.chatUsers.map((usr) =>
          usr.userId === payload ? { ...usr, online: true } : usr
        ),
      };
    },
    setUserDisconnected(state, { payload }: { payload: number }) {
      return {
        ...state,
        chatUsers: state.chatUsers.map((usr) =>
          usr.userId === payload ? { ...usr, online: false } : usr
        ),
      };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      userApi.endpoints.getChatAppointments.matchFulfilled,
      (state, { payload }: { payload: IChatAppointment[] }) => {
        const allConnectedUsers = [
          ...new Set(payload.map((appointment) => appointment.usersId)),
        ];
        console.log(payload, allConnectedUsers);
        state.allConnectedUsers = allConnectedUsers;
        allConnectedUsers.forEach((userId) => {
          // Find the first appointment matching the current userId
          const appointmentDetails = payload.find(
            (appointment) => appointment.usersId === userId
          );

          const activeAppointment = payload.find(
            (appointment) =>
              appointment.active && appointment.usersId === userId
            // && isToday(appointment.AppointmentDate)
          );
          const existingUserIndex = state.chatUsers.findIndex(
            (user) => user.userId === userId
          );

          if (existingUserIndex !== -1) {
            // Update existing user
            state.chatUsers[existingUserIndex].active = !!activeAppointment;
            state.chatUsers[existingUserIndex].appointment = activeAppointment;
          } else {
            state.chatUsers.push({
              active: !!activeAppointment,
              userId: userId,
              fullName: appointmentDetails?.users.fullName || noDataLabel,
              online: false,
              isTyping: false,
              messages: [],
              appointment: activeAppointment,
            });
          }
        });
      }
    );
  },
});
export const { actions } = chatSlice;
export const {
  setWsConnection,
  setConnected,
  endCall,
  addMessage,
  setUserTyping,
  updateChatData,
  setUserConnected,
  setUserDisconnected,
  setChatUsers,
} = chatSlice.actions;
export default chatSlice.reducer;
