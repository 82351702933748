import styles from "./profile.module.css";
import questionnaireDoctor from "../../images/questionnaire_doctor.png";
import uploadIcon from "../../images/upload_icon.svg";
import React, { useState } from "react";
import Modal from "../../components/modals/modal/Modal";
import ChangePasswordPopup from "../../components/modals/changePasswordPopup/changePasswordPopup";
import UpdateUserInfoSuccessPopup from "../../components/modals/updateUserInfoSuccessPopup/updateUserInfoSuccessPopup";
import useMediaQuery from "../../hooks/useMediaQuery";
import { useAppSelector } from "../../services/hooks";
import { errorUserSelector } from "../../services/selectors/auth.selectors";
import ChangeAvatarPopup from "../../components/modals/changeAvatarPopup/changeAvatarPopup";
import { useFormik } from "formik";
import { updateUserValidationSchema } from "../../utils/validation";
import { disorders, mobileWidth } from "../../utils/constants";
import {
  useLazyGetUserQuery,
  useUpdateUserMutation,
} from "../../services/api/user.api";
import { getChangedValues } from "../../utils/utils";
import { userSelector } from "../../services/selectors/user.selectors";
import Select from "react-select";
import BackArrowButton from "../../components/backArrowButton/backArrowButton";
import { useNavigate } from "react-router-dom";

function ProfilePage() {
  const navigate = useNavigate()
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [openUserInfoModal, setOpenUserInfoModal] = useState(false);
  const [openAvatarModal, setOpenAvatarModal] = useState(false);

  const [updateUser, { isError }] = useUpdateUserMutation();
  const [getUser] = useLazyGetUserQuery();

  const user = useAppSelector(userSelector);

  const updateError = useAppSelector(errorUserSelector);

  const mobile = useMediaQuery(mobileWidth);
  console.log("user: ", user);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: user,
    validationSchema: updateUserValidationSchema,
    onSubmit: async (values) => {
      // Handle form submission
      const changedValues = getChangedValues(values, formik.initialValues);
      const body = Object.keys(changedValues).map((key) => {
        return {
          op: "replace",
          path: `/${key}`,
          value: values[key],
        };
      });
      updateUser({ body, id: user.id }).then((response) => {
        if ("data" in response && response.data) {
          setOpenUserInfoModal(true);
          getUser();
        }
      });
    },
  });

  return (
    <div className={styles.profile}>
      {openPasswordModal && (
        <Modal onClose={() => setOpenPasswordModal(false)}>
          <ChangePasswordPopup onClose={() => setOpenPasswordModal(false)} />
        </Modal>
      )}
      {openUserInfoModal && (
        <Modal onClose={() => setOpenUserInfoModal(false)}>
          <UpdateUserInfoSuccessPopup
            onClose={() => setOpenUserInfoModal(false)}
          />
        </Modal>
      )}
      {openAvatarModal && (
        <Modal onClose={() => setOpenAvatarModal(false)}>
          <ChangeAvatarPopup onClose={() => setOpenAvatarModal(false)} />
        </Modal>
      )}
      <BackArrowButton
        text={"למסך הקודם"}
        onClick={() => navigate(-1)}
        position={"left"}
      />

      <div className={styles.profile__content}>
        <h2 className={styles.profile__title}>פרטי חשבון</h2>
        <img
          src={`${user.avatar
            ? user.avatar
            : questionnaireDoctor
            }`}
          alt="תמונת משתמש"
          className={styles.profile__avatarPlaceholder}
        />
        <button
          type="button"
          className={`${styles.profile__addAvatar} ${mobile && styles.profile__addAvatar_mobile
            }`}
          onClick={() => setOpenAvatarModal(true)}
        />
        {user.account && (
          <p className={styles.profile__accountNumber}>
            מספר משתמש {user.account}
          </p>
        )}
      </div>
      <form
        id={"updateUser"}
        onSubmit={formik.handleSubmit}
        className={styles.profile__form}
      >
        <ul className={styles.profile__list}>
          <li>
            <label htmlFor="firstName" className={styles.profile__label}>
              שם פרטי
            </label>
            <input
              type="text"
              className={`${styles.profile__input} ${formik.errors.firstName && styles.profile__input_invalid
                }`}
              name="firstName"
              id="firstName"
              value={formik.values.firstName ?? ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </li>
          <li>
            <label htmlFor="lastName" className={styles.profile__label}>
              שם משפחה
            </label>
            <input
              type="text"
              className={`${styles.profile__input} ${formik.errors.lastName && styles.profile__input_invalid
                }`}
              name="lastName"
              id="lastName"
              value={formik.values.lastName ?? ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </li>
          <li>
            <label htmlFor="degree" className={styles.profile__label}>
              תואר
            </label>
            <input
              readOnly={true}
              type="text"
              className={`${styles.profile__input} ${formik.errors.degree && styles.profile__input_invalid
                }`}
              name="degree"
              id="degree"
              value={formik.values.roles?.Name ?? ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </li>
          <li>
            <label htmlFor="email" className={styles.profile__label}>
              אימייל
            </label>
            <input
              type="email"
              className={`${styles.profile__input} ${formik.errors.email && styles.profile__input_invalid
                }`}
              name="email"
              id="email"
              value={formik.values.email ?? ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </li>
          <li>
            <label htmlFor="specialization" className={styles.profile__label}>
              התמחות
            </label>
            <input
              readOnly={true}
              type="specialization"
              className={`${styles.profile__input} `}
              name="specialization"
              id="specialization"
              value={formik.values.specializations?.Name ?? "אין מידע"}
            />
          </li>
          <li>
            <label htmlFor="vetek" className={styles.profile__label}>
              ותק (בשנים)
            </label>
            <input
              type="number"
              className={`${styles.profile__input} ${formik.errors.email && styles.profile__input_invalid
                }`}
              name="vetek"
              id="vetek"
              value={
                formik.values.vetek
                  ? formik.values.vetek
                  : ""
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </li>
          <li>
            <label htmlFor="migzar" className={styles.profile__label}>
              מגזר
            </label>
            <input
              readOnly={true}
              type="string"
              className={`${styles.profile__input} ${formik.errors.email && styles.profile__input_invalid
                }`}
              name="migzar"
              id="migzar"
              value={
                formik.values?.migzars?.Name
                ?? "אין מידע"
              }
              onBlur={formik.handleBlur}
            />
          </li>
          <li>
            <label htmlFor="gender" className={styles.profile__label}>
              מגדר
            </label>
            <input
              readOnly={true}
              type="string"
              className={`${styles.profile__input} ${formik.errors.email && styles.profile__input_invalid
                }`}
              name="gender"
              id="gender"
              value={
                formik.values.genderId
                  ? formik.values.genders?.Name
                  : "אין מידע"
              }
              onBlur={formik.handleBlur}
            />
          </li>
          <li>
            <label htmlFor="phone" className={styles.profile__label}>
              נייד
            </label>
            <input
              type="string"
              className={`${styles.profile__input} ${formik.errors.phone && styles.profile__input_invalid
                }`}
              name="phone"
              id="phone"
              value={
                formik.values.phone
                  ? formik.values.phone.replace(/[^\d]+/g, "")
                  : ""
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder={formik.values.phone}
            />
          </li>
          <li>
            <div className={styles.profile__info}>
              <span className={styles.profile__label}>סיסמה</span>
              <button
                type="button"
                onClick={() => {
                  setOpenPasswordModal(true);
                }}
                className={styles.profile__changeBtn}
              >
                {mobile ? "" : "עריכת סיסמה"}
              </button>
            </div>
            <p
              className={`${styles.profile__input} ${styles.profile__password}`}
            >
              {[1, 2, 3, 4, 5, 6, 7, 8].map((circle, index) => (
                <span key={index} className={styles.profile__circle} />
              ))}
            </p>
          </li>
          <li>
            <label htmlFor="about" className={styles.profile__label}>
              תחומי טיפול
            </label>
            <Select
              isMulti
              name={"fields"}
              className={`${styles.profile__input} ${styles.profile__textArea} ${styles.height_unset}`}
              options={disorders.map((d) => ({ value: d, label: d }))}
              placeholder={"אנא בחר..."}
              value={formik.values.fields?.map((d) => ({ value: d, label: d }))}
              onChange={(value) =>
                formik.setFieldValue(
                  "fields",
                  value.map((item) => item.value)
                )
              }
            />
          </li>
          <li>
            <label htmlFor="about" className={styles.profile__label}>
              קצת עליי
            </label>
            <textarea
              readOnly={true}
              className={`scroll ${styles.profile__input} ${styles.profile__textArea
                } ${styles.height_unset} ${formik.errors.about && styles.profile__input_invalid
                }`}
              name="about"
              id="about"
              value={formik.values.about || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder={formik.values.about}
            />
          </li>
          <li>
            <label
              htmlFor={"video"}
              className={`${styles.profile__label} ${styles.profile__label_video}`}
            >
              כמו כן, מומלץ להעלות סרטון קצר שהמשתמשים יוכלו לראות
              <img
                src={uploadIcon}
                alt={"להעלות סרטון"}
                className={styles.profile__uploadBtn}
              />
            </label>
            <input
              className={styles.profile__videoInput}
              name="video"
              id="video"
              type="file"
              accept="video/*"
            />
          </li>
        </ul>
      </form>
      {formik.errors && (
        <p className={`${styles.profile__invalid_message}`}>
          {formik.errors.firstName ??
            formik.errors.lastName ??
            formik.errors.email ??
            formik.errors.phone ??
            formik.errors.address}
        </p>
      )}
      {isError && (
        <p className={`${styles.profile__invalid_message}`}>{updateError}</p>
      )}
      <button
        type={"submit"}
        form={"updateUser"}
        disabled={!formik.isValid || formik.isSubmitting || !formik.dirty}
        className={`button ${!mobile && "button_white"} hover ${styles.btn} ${(!formik.isValid || formik.isSubmitting || !formik.dirty) &&
          styles.btn_disabled
          }`}
      >
        עריכת פרטי חשבון
      </button>
    </div>
  );
}

export default ProfilePage;
