import styles from "../../../pages/newPrescription/newPrescription.module.css";
import Select, {
  ActionMeta,
  GroupBase,
  SelectInstance,
  SingleValue,
} from "react-select";
import {
  drugOptions,
  IOption,
  medicineApplicationOptions,
  medicineDosageOptions,
} from "../../../utils/constants";
import React, { useRef } from "react";
import { TPrescriptionForm } from "../../../types/types";

function NewPrescriptionForm({
  form,
  setForm,
}: {
  form: TPrescriptionForm;
  setForm: (value: string, name: string) => void;
}) {
  const drugRef =
    useRef<SelectInstance<IOption, false, GroupBase<IOption>>>(null);
  const applicationOptionsRef =
    useRef<SelectInstance<IOption, false, GroupBase<IOption>>>(null);

  const onSelectChange = (
    value: SingleValue<IOption>,
    { name }: ActionMeta<IOption>
  ) => {
    setForm(value?.label as string, name as string);
  };

  return (
    <form className={styles.form}>
      <div>
        <h4 className={styles.form__subtitle}>שם תרופה</h4>
        <Select
          ref={drugRef}
          options={drugOptions}
          name={"medicine"}
          value={
            form.medicine && typeof form.medicine === "string"
              ? { label: form.medicine, value: form.medicine }
              : undefined
          }
          placeholder={"בחר..."}
          onChange={onSelectChange}
        />
      </div>
      <div>
        <h4 className={styles.form__subtitle}>א. הגשה</h4>
        <Select
          ref={applicationOptionsRef}
          name={"applicationOptions"}
          placeholder={"בחר..."}
          options={medicineApplicationOptions}
          onChange={onSelectChange}
        />
      </div>
      <div>
        <h4 className={styles.form__subtitle}>מינון</h4>
        <div className={styles.form__field}>
          <input
            type="number"
            value={form.dosage}
            id={"dosage"}
            name="dosage"
            className={`${styles.form__input} `}
            onChange={(e) => setForm(e.target.value, "dosage")}
          />
          <Select
            name={"dosageValue"}
            options={medicineDosageOptions}
            onChange={onSelectChange}
            value={
              form.dosageValue
                ? typeof form.dosageValue === "string"
                  ? { label: form.dosageValue, value: form.dosageValue }
                  : form.dosageValue
                : undefined
            }
          />
        </div>
      </div>
      <div>
        <h4 className={styles.form__subtitle}>פעמים ביום</h4>
        <input
          type="number"
          value={form.numberTimesADay}
          id={"numberTimesADay"}
          name="numberTimesADay"
          className={styles.form__input}
          onChange={(e) => setForm(e.target.value, "numberTimesADay")}
        />
      </div>
      <div>
        <h4 className={styles.form__subtitle}>מספר ימים</h4>
        <input
          type="number"
          value={form.days}
          id={"days"}
          name="days"
          className={styles.form__input}
          onChange={(e) => setForm(e.target.value, "days")}
        />
      </div>
      <div>
        <h4 className={styles.form__subtitle}>הערות לתרופה</h4>
        <textarea
          value={form.instructions}
          id={"instructions"}
          name="instructions"
          className={`${styles.form__input} ${styles.form__textarea}`}
          onChange={(e) => setForm(e.target.value, "instructions")}
        />
      </div>
    </form>
  );
}
export default NewPrescriptionForm;
