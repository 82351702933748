import { IVideo } from "../types/types";

export const videoExamples: IVideo[] = [
  {
    link: "https://media.istockphoto.com/id/1451192747/video/vulnerable-man-talks-during-support-group-meeting.mp4?s=mp4-640x640-is&k=20&c=WThIHid8mzzXXlkq1s6hQDm9RwpLm4YQrRp84GS8W4Q=",
    title: "אתה עוצמתי",
    about:
      "זוהי מדיטציה מודרכת של 16 דקות מעצימה ומרפאה על להיות כוחני. זוהי תזכורת לכך שאתה יכול להיות בעל מוח מדיטטיבי עמוק. אתה אדם חזק ואתה חשוב! טיפול עצמי ואהבה עצמית הם כל כך חשובים כי אינך יכול להשפיע עמוקות ואוהבת על אנשים אחרים עד שאתה באמת אוהב ודואג לעצמך",
  },
  {
    link: "https://media.istockphoto.com/id/1219789672/video/female-psychologist-consulting-african-woman-client-during-online-counseling-session.mp4?s=mp4-640x640-is&k=20&c=UhGC5fuM35eA2zCWbDzogHQYrsAUkO50jEARZHHpd5k=",
    title: "מדיטציה ללב",
    about:
      "הקשיבו למדיטציה מודרכת זו של 20 דקות בכל יום וחקור את האהבה והחמלה שבתוך ליבנו כדי למצוא מצב של רגיעה ושלווה פנימית.",
  },
  {
    link: "https://media.istockphoto.com/id/1317225074/video/counsellor-and-people-sitting-in-a-circle-during-a-group-therapy-session.mp4?s=mp4-640x640-is&k=20&c=T2LOupL6ZzMI9pXmvyJ1RwMaxiX6hLKeon8j2hQ2m5w=",
    title: "מדיטציה נגד חרדה",
    about:
      "נכתב וקריינות על ידי ג'ון דייוויסי. ג'ון הוא מאמן, מורה ודובר לחיים בתשומת לב.",
  },
  {
    link: "https://media.istockphoto.com/id/1149405165/video/successful-psychologist-consulting-female-patient-in-office-talking-writing.mp4?s=mp4-640x640-is&k=20&c=Et2-VAy7r_5TxJOJvIx4fXwGeRABb5mET5vrBCnX8tc=",
    title: "מדיטציה לריפוי והשתחררות",
    about:
      "מדיטציה מודרכת של 10 דקות לריפוי, שחרור ושלווה פנימית. לרפא את הלב והפצעים הרגשיים שלך עם הדמיית אנרגיה חיובית, הקשה על צ'אקרת הלב לריפוי אנרגיה והצהרות חיוביות. מדיטציית ריפוי זו תעזור לך לטפח הכרת תודה, סליחה עצמית ופרספקטיבה למסע שלך. מעולה למתחילים",
  },
  {
    link: "https://media.istockphoto.com/id/1413205857/video/group-of-young-multi-ethnic-gym-class-doing-practice-yoga-lesson-together-on-sunrise-morning.mp4?s=mp4-640x640-is&k=20&c=fSLBrMcv8WrQ9dGEX7tMjY5Fhok681tu66Xsx5JQZLc=",
    title: "אתה עוצמתי",
    about:
      "זוהי מדיטציה מודרכת של 16 דקות מעצימה ומרפאה על להיות כוחני. זוהי תזכורת לכך שאתה יכול להיות בעל מוח מדיטטיבי עמוק. אתה אדם חזק ואתה חשוב! טיפול עצמי ואהבה עצמית הם כל כך חשובים כי אינך יכול להשפיע עמוקות ואוהבת על אנשים אחרים עד שאתה באמת אוהב ודואג לעצמך",
  },
  {
    link: "https://media.istockphoto.com/id/1408178995/video/spa-still-life-stones-massage-and-water-relax-close-up-relaxing-view-of-burning-candles-water.mp4?s=mp4-640x640-is&k=20&c=Npc8krlrGkO29HLwGRKpyq8cGFtB8S95xyzHSbDI8zs=",
    title: "מדיטציה ללב",
    about:
      "הקשיבו למדיטציה מודרכת זו של 20 דקות בכל יום וחקור את האהבה והחמלה שבתוך ליבנו כדי למצוא מצב של רגיעה ושלווה פנימית.",
  },
  {
    link: "https://media.istockphoto.com/id/1295220207/video/serene-african-woman-relieving-stress-at-workplace-do-meditation-practice.mp4?s=mp4-640x640-is&k=20&c=Q4k98iuDSEz0qKFfZIukkbwwL0kfKTa9Dv_rfhaHOHk=",
    title: "מדיטציה נגד חרדה",
    about:
      "נכתב וקריינות על ידי ג'ון דייוויסי. ג'ון הוא מאמן, מורה ודובר לחיים בתשומת לב.",
  },
  {
    link: "https://media.istockphoto.com/id/1399551003/video/mindful-concentration-and-breathing.mp4?s=mp4-640x640-is&k=20&c=5vpaljndH1kbNSFXef66BVeMK3y_oTC8RhhFMb_9Szc=",
    title: "מדיטציה לריפוי והשתחררות",
    about:
      "מדיטציה מודרכת של 10 דקות לריפוי, שחרור ושלווה פנימית. לרפא את הלב והפצעים הרגשיים שלך עם הדמיית אנרגיה חיובית, הקשה על צ'אקרת הלב לריפוי אנרגיה והצהרות חיוביות. מדיטציית ריפוי זו תעזור לך לטפח הכרת תודה, סליחה עצמית ופרספקטיבה למסע שלך. מעולה למתחילים",
  },
  {
    link: "https://media.istockphoto.com/id/1413205857/video/group-of-young-multi-ethnic-gym-class-doing-practice-yoga-lesson-together-on-sunrise-morning.mp4?s=mp4-640x640-is&k=20&c=fSLBrMcv8WrQ9dGEX7tMjY5Fhok681tu66Xsx5JQZLc=",
    title: "אתה עוצמתי",
    about:
      "זוהי מדיטציה מודרכת של 16 דקות מעצימה ומרפאה על להיות כוחני. זוהי תזכורת לכך שאתה יכול להיות בעל מוח מדיטטיבי עמוק. אתה אדם חזק ואתה חשוב! טיפול עצמי ואהבה עצמית הם כל כך חשובים כי אינך יכול להשפיע עמוקות ואוהבת על אנשים אחרים עד שאתה באמת אוהב ודואג לעצמך",
  },
  {
    link: "https://media.istockphoto.com/id/1408178995/video/spa-still-life-stones-massage-and-water-relax-close-up-relaxing-view-of-burning-candles-water.mp4?s=mp4-640x640-is&k=20&c=Npc8krlrGkO29HLwGRKpyq8cGFtB8S95xyzHSbDI8zs=",
    title: "מדיטציה ללב",
    about:
      "הקשיבו למדיטציה מודרכת זו של 20 דקות בכל יום וחקור את האהבה והחמלה שבתוך ליבנו כדי למצוא מצב של רגיעה ושלווה פנימית.",
  },
  {
    link: "https://media.istockphoto.com/id/1295220207/video/serene-african-woman-relieving-stress-at-workplace-do-meditation-practice.mp4?s=mp4-640x640-is&k=20&c=Q4k98iuDSEz0qKFfZIukkbwwL0kfKTa9Dv_rfhaHOHk=",
    title: "מדיטציה נגד חרדה",
    about:
      "נכתב וקריינות על ידי ג'ון דייוויסי. ג'ון הוא מאמן, מורה ודובר לחיים בתשומת לב.",
  },
  {
    link: "https://media.istockphoto.com/id/1399551003/video/mindful-concentration-and-breathing.mp4?s=mp4-640x640-is&k=20&c=5vpaljndH1kbNSFXef66BVeMK3y_oTC8RhhFMb_9Szc=",
    title: "מדיטציה לריפוי והשתחררות",
    about:
      "מדיטציה מודרכת של 10 דקות לריפוי, שחרור ושלווה פנימית. לרפא את הלב והפצעים הרגשיים שלך עם הדמיית אנרגיה חיובית, הקשה על צ'אקרת הלב לריפוי אנרגיה והצהרות חיוביות. מדיטציית ריפוי זו תעזור לך לטפח הכרת תודה, סליחה עצמית ופרספקטיבה למסע שלך. מעולה למתחילים",
  },
  {
    link: "https://media.istockphoto.com/id/1413205857/video/group-of-young-multi-ethnic-gym-class-doing-practice-yoga-lesson-together-on-sunrise-morning.mp4?s=mp4-640x640-is&k=20&c=fSLBrMcv8WrQ9dGEX7tMjY5Fhok681tu66Xsx5JQZLc=",
    title: "אתה עוצמתי",
    about:
      "זוהי מדיטציה מודרכת של 16 דקות מעצימה ומרפאה על להיות כוחני. זוהי תזכורת לכך שאתה יכול להיות בעל מוח מדיטטיבי עמוק. אתה אדם חזק ואתה חשוב! טיפול עצמי ואהבה עצמית הם כל כך חשובים כי אינך יכול להשפיע עמוקות ואוהבת על אנשים אחרים עד שאתה באמת אוהב ודואג לעצמך",
  },
  {
    link: "https://media.istockphoto.com/id/1408178995/video/spa-still-life-stones-massage-and-water-relax-close-up-relaxing-view-of-burning-candles-water.mp4?s=mp4-640x640-is&k=20&c=Npc8krlrGkO29HLwGRKpyq8cGFtB8S95xyzHSbDI8zs=",
    title: "מדיטציה ללב",
    about:
      "הקשיבו למדיטציה מודרכת זו של 20 דקות בכל יום וחקור את האהבה והחמלה שבתוך ליבנו כדי למצוא מצב של רגיעה ושלווה פנימית.",
  },
  {
    link: "https://media.istockphoto.com/id/1295220207/video/serene-african-woman-relieving-stress-at-workplace-do-meditation-practice.mp4?s=mp4-640x640-is&k=20&c=Q4k98iuDSEz0qKFfZIukkbwwL0kfKTa9Dv_rfhaHOHk=",
    title: "מדיטציה נגד חרדה",
    about:
      "נכתב וקריינות על ידי ג'ון דייוויסי. ג'ון הוא מאמן, מורה ודובר לחיים בתשומת לב.",
  },
  {
    link: "https://media.istockphoto.com/id/1399551003/video/mindful-concentration-and-breathing.mp4?s=mp4-640x640-is&k=20&c=5vpaljndH1kbNSFXef66BVeMK3y_oTC8RhhFMb_9Szc=",
    title: "מדיטציה לריפוי והשתחררות",
    about:
      "מדיטציה מודרכת של 10 דקות לריפוי, שחרור ושלווה פנימית. לרפא את הלב והפצעים הרגשיים שלך עם הדמיית אנרגיה חיובית, הקשה על צ'אקרת הלב לריפוי אנרגיה והצהרות חיוביות. מדיטציית ריפוי זו תעזור לך לטפח הכרת תודה, סליחה עצמית ופרספקטיבה למסע שלך. מעולה למתחילים",
  },
  {
    link: "https://media.istockphoto.com/id/1413205857/video/group-of-young-multi-ethnic-gym-class-doing-practice-yoga-lesson-together-on-sunrise-morning.mp4?s=mp4-640x640-is&k=20&c=fSLBrMcv8WrQ9dGEX7tMjY5Fhok681tu66Xsx5JQZLc=",
    title: "אתה עוצמתי",
    about:
      "זוהי מדיטציה מודרכת של 16 דקות מעצימה ומרפאה על להיות כוחני. זוהי תזכורת לכך שאתה יכול להיות בעל מוח מדיטטיבי עמוק. אתה אדם חזק ואתה חשוב! טיפול עצמי ואהבה עצמית הם כל כך חשובים כי אינך יכול להשפיע עמוקות ואוהבת על אנשים אחרים עד שאתה באמת אוהב ודואג לעצמך",
  },
  {
    link: "https://media.istockphoto.com/id/1408178995/video/spa-still-life-stones-massage-and-water-relax-close-up-relaxing-view-of-burning-candles-water.mp4?s=mp4-640x640-is&k=20&c=Npc8krlrGkO29HLwGRKpyq8cGFtB8S95xyzHSbDI8zs=",
    title: "מדיטציה ללב",
    about:
      "הקשיבו למדיטציה מודרכת זו של 20 דקות בכל יום וחקור את האהבה והחמלה שבתוך ליבנו כדי למצוא מצב של רגיעה ושלווה פנימית.",
  },
  {
    link: "https://media.istockphoto.com/id/1295220207/video/serene-african-woman-relieving-stress-at-workplace-do-meditation-practice.mp4?s=mp4-640x640-is&k=20&c=Q4k98iuDSEz0qKFfZIukkbwwL0kfKTa9Dv_rfhaHOHk=",
    title: "מדיטציה נגד חרדה",
    about:
      "נכתב וקריינות על ידי ג'ון דייוויסי. ג'ון הוא מאמן, מורה ודובר לחיים בתשומת לב.",
  },
  {
    link: "https://media.istockphoto.com/id/1399551003/video/mindful-concentration-and-breathing.mp4?s=mp4-640x640-is&k=20&c=5vpaljndH1kbNSFXef66BVeMK3y_oTC8RhhFMb_9Szc=",
    title: "מדיטציה לריפוי והשתחררות",
    about:
      "מדיטציה מודרכת של 10 דקות לריפוי, שחרור ושלווה פנימית. לרפא את הלב והפצעים הרגשיים שלך עם הדמיית אנרגיה חיובית, הקשה על צ'אקרת הלב לריפוי אנרגיה והצהרות חיוביות. מדיטציית ריפוי זו תעזור לך לטפח הכרת תודה, סליחה עצמית ופרספקטיבה למסע שלך. מעולה למתחילים",
  },
  {
    link: "https://media.istockphoto.com/id/1413205857/video/group-of-young-multi-ethnic-gym-class-doing-practice-yoga-lesson-together-on-sunrise-morning.mp4?s=mp4-640x640-is&k=20&c=fSLBrMcv8WrQ9dGEX7tMjY5Fhok681tu66Xsx5JQZLc=",
    title: "אתה עוצמתי",
    about:
      "זוהי מדיטציה מודרכת של 16 דקות מעצימה ומרפאה על להיות כוחני. זוהי תזכורת לכך שאתה יכול להיות בעל מוח מדיטטיבי עמוק. אתה אדם חזק ואתה חשוב! טיפול עצמי ואהבה עצמית הם כל כך חשובים כי אינך יכול להשפיע עמוקות ואוהבת על אנשים אחרים עד שאתה באמת אוהב ודואג לעצמך",
  },
  {
    link: "https://media.istockphoto.com/id/1408178995/video/spa-still-life-stones-massage-and-water-relax-close-up-relaxing-view-of-burning-candles-water.mp4?s=mp4-640x640-is&k=20&c=Npc8krlrGkO29HLwGRKpyq8cGFtB8S95xyzHSbDI8zs=",
    title: "מדיטציה ללב",
    about:
      "הקשיבו למדיטציה מודרכת זו של 20 דקות בכל יום וחקור את האהבה והחמלה שבתוך ליבנו כדי למצוא מצב של רגיעה ושלווה פנימית.",
  },
  {
    link: "https://media.istockphoto.com/id/1295220207/video/serene-african-woman-relieving-stress-at-workplace-do-meditation-practice.mp4?s=mp4-640x640-is&k=20&c=Q4k98iuDSEz0qKFfZIukkbwwL0kfKTa9Dv_rfhaHOHk=",
    title: "מדיטציה נגד חרדה",
    about:
      "נכתב וקריינות על ידי ג'ון דייוויסי. ג'ון הוא מאמן, מורה ודובר לחיים בתשומת לב.",
  },
  {
    link: "https://media.istockphoto.com/id/1399551003/video/mindful-concentration-and-breathing.mp4?s=mp4-640x640-is&k=20&c=5vpaljndH1kbNSFXef66BVeMK3y_oTC8RhhFMb_9Szc=",
    title: "מדיטציה לריפוי והשתחררות",
    about:
      "מדיטציה מודרכת של 10 דקות לריפוי, שחרור ושלווה פנימית. לרפא את הלב והפצעים הרגשיים שלך עם הדמיית אנרגיה חיובית, הקשה על צ'אקרת הלב לריפוי אנרגיה והצהרות חיוביות. מדיטציית ריפוי זו תעזור לך לטפח הכרת תודה, סליחה עצמית ופרספקטיבה למסע שלך. מעולה למתחילים",
  },
  {
    link: "https://media.istockphoto.com/id/1413205857/video/group-of-young-multi-ethnic-gym-class-doing-practice-yoga-lesson-together-on-sunrise-morning.mp4?s=mp4-640x640-is&k=20&c=fSLBrMcv8WrQ9dGEX7tMjY5Fhok681tu66Xsx5JQZLc=",
    title: "אתה עוצמתי",
    about:
      "זוהי מדיטציה מודרכת של 16 דקות מעצימה ומרפאה על להיות כוחני. זוהי תזכורת לכך שאתה יכול להיות בעל מוח מדיטטיבי עמוק. אתה אדם חזק ואתה חשוב! טיפול עצמי ואהבה עצמית הם כל כך חשובים כי אינך יכול להשפיע עמוקות ואוהבת על אנשים אחרים עד שאתה באמת אוהב ודואג לעצמך",
  },
  {
    link: "https://media.istockphoto.com/id/1408178995/video/spa-still-life-stones-massage-and-water-relax-close-up-relaxing-view-of-burning-candles-water.mp4?s=mp4-640x640-is&k=20&c=Npc8krlrGkO29HLwGRKpyq8cGFtB8S95xyzHSbDI8zs=",
    title: "מדיטציה ללב",
    about:
      "הקשיבו למדיטציה מודרכת זו של 20 דקות בכל יום וחקור את האהבה והחמלה שבתוך ליבנו כדי למצוא מצב של רגיעה ושלווה פנימית.",
  },
  {
    link: "https://media.istockphoto.com/id/1295220207/video/serene-african-woman-relieving-stress-at-workplace-do-meditation-practice.mp4?s=mp4-640x640-is&k=20&c=Q4k98iuDSEz0qKFfZIukkbwwL0kfKTa9Dv_rfhaHOHk=",
    title: "מדיטציה נגד חרדה",
    about:
      "נכתב וקריינות על ידי ג'ון דייוויסי. ג'ון הוא מאמן, מורה ודובר לחיים בתשומת לב.",
  },
  {
    link: "https://media.istockphoto.com/id/1399551003/video/mindful-concentration-and-breathing.mp4?s=mp4-640x640-is&k=20&c=5vpaljndH1kbNSFXef66BVeMK3y_oTC8RhhFMb_9Szc=",
    title: "מדיטציה לריפוי והשתחררות",
    about:
      "מדיטציה מודרכת של 10 דקות לריפוי, שחרור ושלווה פנימית. לרפא את הלב והפצעים הרגשיים שלך עם הדמיית אנרגיה חיובית, הקשה על צ'אקרת הלב לריפוי אנרגיה והצהרות חיוביות. מדיטציית ריפוי זו תעזור לך לטפח הכרת תודה, סליחה עצמית ופרספקטיבה למסע שלך. מעולה למתחילים",
  },
];
