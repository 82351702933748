import { RootState } from "../types";

export const userSelector = (state: RootState) => state.user.user;
export const currentUserSelector = (state: RootState) => state.user.currentUser;
export const currentInquirySelector = (state: RootState) => state.user.scroll.currentInquiryId;
export const currentTreatmentPlanSelector = (state: RootState) => state.user.scroll.currentTreatmentPlanId;
export const therapistIdSelector = (state: RootState) => state.user.user.id;
export const therapistUsersSelector = (state: RootState) =>
  state.user.user.users;
export const userPlanSelector = (state: RootState) => state.user.user.planId;
export const userAppointmentsSelector = (state: RootState) =>
  state.user.user.appointments;
export const userTherapistsSelector = (state: RootState) =>
  state.user.user.therapists;
export const availabilitySelector = (state: RootState) =>
  state.user.user.availability;
export const mainPageUpdatesSelector = (state: RootState) =>
  state.user.user.mainPageUpdates;
export const inquiriesSelector = (state: RootState) =>
  state.user.user.inquiries;
export const documentsSelector = (state: RootState) =>
  state.user.user.documents;
export const actionReportQuestionsSelector = (state: RootState) =>
  state.user.user.actionReportQuestions;
export const therapistWithUsersSelector = (state: RootState) =>
  state.user.therapistWithUsers;
export const usersWithOutTherapistSelector = (state: RootState) =>
  state.user.usersWithOutTherapist;
export const usersHosenSderotSelector = (state: RootState) =>
  state.user.usersHosenSderot;
export const groupedUsersByFakeCompanyIdSelector = (state: RootState) =>
  state.user.groupedUsersWithoutTherapistByFakeCompanyId;
export const statusesSelector = (state: RootState) => state.user.statuses;
export const showUserJoinedModalSelector = (state: RootState) =>
  state.user.showUserJoinedModal;
export const calendarAppointmentSelector = (state: RootState) =>
  state.user.calendarAppointments
export const therapistCompanyIdSelector = (state: RootState) =>
  state.user.user.companyId;
export const allTreatmentPlansSelector = (state: RootState) =>
  state.user.allTreatmentPlan
export const therapistTreatmentPlansSelector = (state: RootState) =>
  state.user.user.treatmentPlan
export const mediasSelector = (state: RootState) =>
  state.user.medias
export const migzarsSelector = (state: RootState) =>
  state.user.migzars
export const gendersSelector = (state: RootState) =>
    state.user.genders
export const rolesSelector = (state: RootState) => 
  state.user.roles;
export const RRMListSelector = (state: RootState) => 
  state.user.RRMList;
export const questionnairWithoutUserButtonsSelector = (state: RootState) => 
  state.user.questionnairWithoutUserButtons;
export const questionnairWithUserButtonsSelector = (state: RootState) => 
  state.user.questionnairWithUserButtons;
export const UserQuestionnairSiteLinkSelector = (state: RootState) => 
  state.user.userQuestionnairSiteLink;

