import { Category } from "../types/types";

export const menuCategories: Record<Category, string> = {
  [Category.Messages]: "הודעות",
  [Category.Support]: "תמיכה טכנית",
  [Category.Terms]: "תקנון ומדיניות פרטיות",
  [Category.Profile]: "הפרופיל שלי",
  [Category.Main]: "דף הבית",
  [Category.Library]: "ספריית פריוונצר",
  [Category.Calendar]: "יומן פגישות",
  [Category.Forum]: "פורום",
  [Category.Users]: "משתמשים",
};
export interface IContactUsTheme {
  title: string;
  text: string;
}
