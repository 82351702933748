import styles from "./questionReport.module.css";
import {
  TQuestionnaire,
  TQuestionnaireQuestion,
} from "../../services/types/user.types";
function QuestionReport({
  report,
  isTestUser,
  isRightSide
}: {
  isTestUser: boolean;
  report: { questionnaire: TQuestionnaire; results: TQuestionnaireQuestion[] };
  isRightSide: boolean;
}) {
  console.log("report: " , report.results);
  
  return (
    <ul className={`${styles.list} ${isRightSide ? styles.right_table : styles.left_table} scroll scroll_thin scroll_rounded`}>
      <li className={` ${styles.li} ${styles.li_header}`}>
        <p className={styles.text}>שאלה</p>
        <p className={styles.text}>תשובה</p>
        <p className={styles.text}>{isTestUser ? "ציון" : ""}</p>
      </li>
      {report.results.length > 0 ? (
        report.results.map((q, index) => (
          <li key={index} className={styles.li}>
            <p className={styles.text}>{q.Desc}</p>
            <p className={styles.text}>{q.Answer || "-"}</p>
            <p className={styles.text}>{isTestUser ? q.Score : ""}</p>
          </li>
        ))
      ) : (
        <p className={styles.noResults}>לא נמצאו תוצאות</p>
      )}
    </ul>
  );
}

export default QuestionReport;
