import popupStyles from "./cancleAppointmentPopup.module.css";
import doctor from "../../../images/questionnaire_doctor.png";
import { ITreatmentPlan } from "../../../types/types";
import { useAppSelector } from "../../../services/hooks";
import { userSelector } from "../../../services/selectors/user.selectors";
import { useNavigate } from "react-router-dom";
import { replaceVariables } from "../../../utils/utils";
import { useAlternativeAppointmentProgramDateMutation } from "../../../services/api/user.api";
import { TCalendarAppointment } from "../../../services/types/user.types";

interface ICancleAppointmentPopupProps {
    onClose: () => void;
    onFullClose: () => void;
    setProgramDates: (programDatesChanged: TCalendarAppointment[] | null) => void;
    onSuccess: (change: boolean) => void;
    date: string;
    treatmentPlan: ITreatmentPlan;
}

function CancleAppointmentPopup({
    onClose,
    onFullClose,
    date,
    treatmentPlan,
    setProgramDates,
    onSuccess
}: ICancleAppointmentPopupProps) {
    const navigate = useNavigate()

    const therapist = useAppSelector(userSelector)

    const [findAlternativeMeeting] = useAlternativeAppointmentProgramDateMutation()

    const AlternativeMeeting = () => {
        const dateToDelete = date.split("/").reverse().map((val, idx) => (idx === 1 ? +val - 1 : +val))
            .reduce((d, val, idx) => {
                (d as any)[`set${["FullYear", "Month", "Date"][idx]}`](val);
                return d;
            }, new Date(0))
            .toISOString()
            .replace("T00:00:00.000Z", "T00:00:00");

        findAlternativeMeeting(
            {
                ...treatmentPlan.appointmentsProgram,
                dateToDelete: dateToDelete,
                deleteType: 2
            })
            .then(res => {
                if ("data" in res) {
                    setProgramDates(res.data)
                    onClose();
                    onSuccess(true)
                } else {
                    console.error("not success..............", res);
                }
            }

            )
    }
    return (
        <article style={{ placeItems: 'center' }} className={popupStyles.popup}>
            <img
                src={doctor}
                alt="תמונה של וונצ’י"
            />
            <p className={popupStyles.popup__text}>{`שלום ${therapist.firstName}, `}</p>
            <p className={popupStyles.popup__title}>
                {`אני מבין ש${replaceVariables("@gender", therapist)} רוצה לבטל את מפגש הטיפול ליום:`}
            </p>
            <p className={popupStyles.popup__text}>{date}</p>
            <p className={popupStyles.popup__title}>
                {`אני יכול לתאם לך מפגש חלופי`}
            </p>
            <button
                className={`${popupStyles.popup__nextBtn}`}
                onClick={AlternativeMeeting}
            >
                כן, תתאם לי מפגש חלופי
            </button>
            <button
                className={`button ${popupStyles.popup__nextBtn} ${popupStyles.popup__nextBtn_white}`}
                onClick={() => {
                    onFullClose();
                    navigate('/calendar');
                }}
            >
                לא, תעביר אותי ליומן
            </button>
            <h4 className={popupStyles.popup__title}>
                *לא ניתן לבטל פגישה ללא פגישה חלופית
            </h4>

        </article>
    );
}

export default CancleAppointmentPopup;
