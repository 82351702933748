import styles from "./closeChatButton.module.css";
import React, { useState } from "react";
import {
  IChatAppointment,
  TUpdateChatAppointmentEntry,
} from "../../services/types/user.types";
import { useAppSelector } from "../../services/hooks";
import { userSelector } from "../../services/selectors/user.selectors";
import {
  useLazyGetChatAppointmentsQuery,
  useUpdateChatAppointmentMutation,
} from "../../services/api/user.api";
import Modal from "../modals/modal/Modal";
import GeneralSuccessPopup from "../modals/generalSuccessPopup/generalSuccessPopup";
import ErrorPopup from "../modals/errorPopup/errorPopup";
import { END_APPOINTMENT, formatDateTime } from "../../utils/constants";
import { chatConnectionSelector } from "../../services/selectors/chat.selector";
import { useNavigate } from "react-router-dom";

function CloseChatButton({ appointment }: { appointment: IChatAppointment }) {
  const navigate = useNavigate();
  const therapist = useAppSelector(userSelector);
  const connection = useAppSelector(chatConnectionSelector);

  const [updateChatAppointment] = useUpdateChatAppointmentMutation();
  const [getAllAppointments] = useLazyGetChatAppointmentsQuery();

  const [openModal, setOpenModal] = useState("");

  const closeChatAppointment = (appointment: IChatAppointment) => {
    const body: TUpdateChatAppointmentEntry[] = [
      {
        op: "replace",
        path: "/EndTime",
        value: formatDateTime(new Date()).split("T")[1].replace("Z", ""),
      },
      { op: "replace", path: "/Comments", value: "closed by therapist" },
      { op: "replace", path: "/Active", value: 0 },
    ];
    const message = {
      type: END_APPOINTMENT,
      senderId: Number(therapist.id),
      content: therapist.fullName,
      receiverId: appointment?.usersId,
      appointmentId: appointment?.id,
    };
    updateChatAppointment({ body, appointmentId: appointment.id }).then(
      (res) => {
        if ("data" in res && res.data) {
          setOpenModal("success");
          connection?.invoke("SendMessage", message);
        } else if ("error" in res) {
          setOpenModal("error");
        }
      }
    );
  };

  return (
    <>
      <button
        onClick={(e) => {
          e.stopPropagation();
          appointment && closeChatAppointment(appointment);
          navigate("/main");
        }}
        className={` button ${styles.btn} ${styles.sidebar__btn_type_closeChat} hover`}
      >
        סיים שיחה
      </button>
      {openModal === "success" && (
        <Modal
          onClose={() => {
            getAllAppointments(therapist.id);
            setOpenModal("");
          }}
        >
          <GeneralSuccessPopup
            onClose={() => {
              getAllAppointments(therapist.id);
              setOpenModal("");
            }}
          />
        </Modal>
      )}
      {openModal === "error" && (
        <Modal onClose={() => setOpenModal("")}>
          <ErrorPopup onClose={() => setOpenModal("")} />
        </Modal>
      )}
    </>
  );
}
export default CloseChatButton;
