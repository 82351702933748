import { useNavigate } from "react-router-dom";
import logo from "../../images/logo.png";
import forgotPasswordStyles from "./restorePassword.module.css";
import venchy from "../../images/venchy_welcome.png";
import { useFormik } from "formik";
import { restorePasswordValidationSchema } from "../../utils/validation";
import { useRestorePasswordMutation } from "../../services/api/auth.api";
import { useAppSelector } from "../../services/hooks";
import { errorUserSelector } from "../../services/selectors/auth.selectors";
import { useState } from "react";
import Modal from "../../components/modals/modal/Modal";
import RestorePasswordSuccessPopup from "../../components/modals/restore_password_success/restore_password_success_popup";
import BackArrowButton from "../../components/backArrowButton/backArrowButton";

function RestorePasswordPage() {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [restorePassword, { isError }] = useRestorePasswordMutation();
  const restoreError =
    useAppSelector(errorUserSelector) || "משהו השתבש, אנא נסה שוב מאוחר יותר";

  const formik = useFormik({
    initialValues: {
      email_or_phone: "",
    },
    validationSchema: restorePasswordValidationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      // Handle form submission
      const response = await restorePassword(values.email_or_phone);
      if ("data" in response && response.data.success) {
        //open modal
        setOpenModal(true);
        formik.resetForm();
      }
      setSubmitting(false);
    },
  });

  return (
    <main className={forgotPasswordStyles.main}>
      <article className={forgotPasswordStyles.password}>
        <BackArrowButton text={"למסך הקודם"} onClick={() => navigate(-1)} />
        <img
          className={forgotPasswordStyles.password__logo}
          src={logo}
          alt="logo"
        />
        <img
          className={forgotPasswordStyles.password__pix}
          src={venchy}
          alt="ליווי של מדריך אישי אנושי ומקצועי"
        />
        <h2 className={forgotPasswordStyles.password__title}>
          שכחת סיסמה או מספר משתמש?
        </h2>
        <p className={forgotPasswordStyles.password__text}>
          רשום את מספר הנייד או את כתובת הדוא"ל שלך למטה. אם נמצא אותך במסד
          הנתונים של האתר, ישלח אליך דוא"ל לכתובת הדוא"ל שלך, עם הוראות כיצד
          ניתן להיכנס לאתר.
        </p>
        <form
          onSubmit={formik.handleSubmit}
          className={forgotPasswordStyles.password__buttonBar}
        >
          <input
            type={"text"}
            name="email_or_phone"
            id="email_or_phone"
            placeholder="דואר אלקטרוני או מספר חשבון"
            required={true}
            value={formik.values.email_or_phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={forgotPasswordStyles.password__input}
          />
          {formik.errors.email_or_phone && (
            <p className={`error`}>{formik.errors.email_or_phone}</p>
          )}
          {isError && <p className={`error`}>{restoreError}</p>}

          <button
            type={"submit"}
            disabled={
              !formik.values.email_or_phone ||
              !formik.isValid ||
              formik.isSubmitting
            }
            className={`button ${forgotPasswordStyles.password__btn} ${
              !formik.values.email_or_phone ||
              !formik.isValid ||
              formik.isSubmitting
                ? forgotPasswordStyles.password__btn_disabled
                : "hover"
            }`}
          >
            שלח
          </button>
        </form>
        {openModal && (
          <Modal onClose={() => setOpenModal(false)}>
            <RestorePasswordSuccessPopup onClose={() => setOpenModal(false)} />
          </Modal>
        )}
      </article>
    </main>
  );
}

export default RestorePasswordPage;
