import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import chatStyles from "./chat.module.css";
import {
  chatNoteTypes,
  mobileWidth,
  noDataLabel,
  TYPING_CODE,
} from "../../utils/constants";
import useMediaQuery from "../../hooks/useMediaQuery";
import { useAppSelector } from "../../services/hooks";
import { userSelector } from "../../services/selectors/user.selectors";
import defaultAvatar from "../../images/default_avatar.png";
import useAutosizeTextArea from "../../hooks/useAutosizeTextarea";
import { getDate } from "../../utils/utils";
import ChatSideBar from "../chatSidebar/chatSideBar";
import useTypingIndicator from "../../services/hooks/useTypingIndicator";
import {
  callEndedSelector,
  chatConnectionSelector,
  chatUsersSelector,
} from "../../services/selectors/chat.selector";
import { IChatMessage } from "../../types/types";
import MessageTest from "../message/messageTest";
import { SocketContext } from "../../SocketContext";
import CallEndedNotificationPopup from "../modals/callEndedNotification/callEndedNotificationPopup";
import Modal from "../modals/modal/Modal";
import ChatNote from "../chatNote/chatNote";
import { useActions } from "../../services/hooks/useActions";
import { IUserChatData } from "../../services/types";

const Chat = ({
  openMenu,
  activeUser,
  setActiveUser,
}: {
  openMenu: boolean;
  activeUser: undefined | IUserChatData;
  setActiveUser: Dispatch<SetStateAction<undefined | IUserChatData>>;
}) => {
  const messagesEndRef = useRef(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const mobile = useMediaQuery(mobileWidth);
  const therapist = useAppSelector(userSelector);
  const connection = useAppSelector(chatConnectionSelector);
  const isConnected = connection?.state === "Connected";
  const callEnded = useAppSelector(callEndedSelector);

  const { endCall } = useActions();

  const { callUser, startCall, leaveCall, stream } = useContext(SocketContext);

  //endCall if user disconnected
  useEffect(
    () => {
      if (startCall && !activeUser?.online) {
        leaveCall();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeUser?.online]
  );
  // console.log(activeUser, chatUsers);
  const [value, setValue] = useState("");
  const [openSideBar, setOpenSideBar] = useState(true);

  useAutosizeTextArea(textAreaRef.current, value);

  const { isTyping, startTyping } = useTypingIndicator(4000);

  const handleSendMessage = useCallback(
    (inputValue: string, type: string) => {
      if (!isConnected || !inputValue) return;
      const message = {
        type,
        senderId: Number(therapist.id),
        content: inputValue,
        receiverId: activeUser?.userId,
        appointmentId: activeUser?.appointment?.id,
      };
      if (isConnected && activeUser) {
        connection.invoke("SendMessage", message);
      }
    },
    [isConnected, connection, activeUser, therapist.id]
  );

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code === "Enter" && event.ctrlKey) {
        event.preventDefault();
        setValue((prev) => `${prev}\n`); // Add a new line to the text
      } else if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        handleSendMessage(value, "Message");
        setValue("");
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [handleSendMessage, value]);

  useEffect(() => {
    if (isConnected) {
      handleSendMessage(`${TYPING_CODE}:${isTyping ? 1 : 0}`, TYPING_CODE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTyping]);

  const messages: { date: string; messages: IChatMessage[] }[] = [];

  activeUser?.messages?.forEach((m) => {
    const date = m.sentDate.split("T")[0]; // Extract date part only

    // Find if there's already a group for this date
    const existingGroup = messages.find((group) => group.date === date);

    if (existingGroup) {
      // If a group for the date exists, add the message to it
      // @ts-ignore
      existingGroup.messages.push(m);
    } else {
      // Otherwise, create a new group for this date
      // @ts-ignore
      messages.push({ date: date, messages: [m] });
    }
  });

  //scroll to bottom on open
  useEffect(() => {
    if (messagesEndRef.current) {
      // @ts-ignore
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [openSideBar]);

  //scroll to bottom on new message
  useEffect(() => {
    if (messagesEndRef.current) {
      // @ts-ignore
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [activeUser?.messages, isTyping]);

  const inputPlaceholder = isConnected
    ? activeUser?.online
      ? "כתוב הודעה"
      : "משתמש לא מחובר"
    : "אינך מחובר";

  if (!activeUser) return null;
  return (
    <>
      {mobile && openSideBar ? (
        <ChatSideBar
          setOpenSideBar={setOpenSideBar}
          openMenu={openMenu}
          activeUser={activeUser}
          setActiveUser={setActiveUser}
        />
      ) : (
        <div className={chatStyles.container}>
          <div className={chatStyles.nameContainer}>
            {activeUser && (
              <>
                <button
                  className={`${chatStyles.btn} ${chatStyles.videoBtn} ${
                    (!activeUser?.online || !isConnected) &&
                    chatStyles.sendBtn_disabled
                  }`}
                  disabled={!activeUser?.online || !isConnected}
                  onClick={() => {
                    // if (!stream) {
                    //   setShowMediaDevicesErrorPopup(true);
                    // } else
                    if (activeUser && activeUser.online && isConnected) {
                      callUser(
                        connection,
                        {
                          name: activeUser.fullName,
                          id: activeUser.userId,
                        },
                        {
                          name: therapist?.fullName,
                          avatar: therapist?.avatar || null,
                        }
                      );
                    }
                  }}
                />

                <img
                  src={defaultAvatar}
                  alt={activeUser?.fullName || noDataLabel}
                  className={chatStyles.avatar}
                />
                <div className={chatStyles.info}>
                  <h1 className={chatStyles.name}>
                    {activeUser?.fullName || noDataLabel}
                  </h1>
                  <p className={chatStyles.online}>
                    {activeUser?.online ? "מחובר" : "לא מחובר"}
                  </p>
                  <p className={chatStyles.online}>
                    appointmentId: {activeUser.appointment?.id}, userId:
                    {activeUser.userId}
                  </p>
                  {/*<a*/}
                  {/*  href={`https://app-test.bpreven.com/ChatTestPage/${activeUser.userId}/${activeUser.appointment?.Id}`}*/}
                  {/*  className={chatStyles.online}*/}
                  {/*  target="_blank"*/}
                  {/*  rel="noreferrer"*/}
                  {/*>*/}
                  {/*  Click here: appointmentId: {activeUser.appointment?.Id},*/}
                  {/*  userId:*/}
                  {/*  {activeUser.userId}*/}
                  {/*</a>*/}
                </div>
              </>
            )}
            {mobile && (
              <button
                className={chatStyles.closeBtn}
                onClick={() => setOpenSideBar(true)}
              />
            )}
          </div>

          <div className={chatStyles.messagesWrapper}>
            <>
              {messages.map((m, index) => (
                <div key={index} className={chatStyles.dateSection}>
                  <p className={chatStyles.date}>{getDate(m.date)}</p>
                  {m.messages?.map((msg, ind) => (
                    <MessageTest
                      key={ind}
                      message={msg}
                      isOwnMessage={
                        msg.senderId.toString() === therapist.id.toString()
                      }
                    />
                  ))}
                </div>
              ))}
              {messages.length < 1 && (
                <div className={chatStyles.text}>אין הודעות עדיין</div>
              )}
              <div ref={messagesEndRef} />
            </>
          </div>
          {activeUser?.isTyping && (
            <ChatNote
              name={activeUser.fullName}
              type={chatNoteTypes.isTyping}
            />
          )}
          {!stream && (
            <ChatNote
              name={""}
              type={chatNoteTypes.text}
              text={
                "עליך לאפשר לאפליקציה זו גישה למצלמה ולמיקרופון שלך כדי להשתמש בצ'אט וידאו."
              }
            />
          )}
          <div className={chatStyles.replyBar}>
            <textarea
              className={`${chatStyles.message} ${chatStyles.textArea}`}
              ref={textAreaRef}
              rows={1}
              placeholder={inputPlaceholder}
              onChange={(e) => {
                setValue(e.target.value);
                startTyping();
              }}
              value={value}
              disabled={!isConnected || !activeUser}
            />
            <button
              className={`${chatStyles.btn} ${chatStyles.sendBtn} ${
                (!isConnected || !value) && chatStyles.sendBtn_disabled
              }`}
              disabled={!isConnected || !value}
              onClick={() => {
                handleSendMessage(value, "Message");
                setValue("");
              }}
            />
          </div>
        </div>
      )}
      {callEnded && (
        <Modal
          onClose={() => {
            endCall(undefined);
            leaveCall();
          }}
        >
          <CallEndedNotificationPopup
            by={callEnded}
            onClose={() => {
              endCall(undefined);
              leaveCall();
            }}
          />
        </Modal>
      )}
    </>
  );
};

export default Chat;
