import messageStyles from "./message.module.css";
import defaultAvatar from "../../images/default_avatar.png";
import questionnaireDoctor from "../../images/questionnaire_doctor.png";

import { getTimeFromTimestamp } from "../../utils/utils";
import { IChatMessage } from "../../types/types";
import { useAppSelector } from "../../services/hooks";
import { userSelector } from "../../services/selectors/user.selectors";
import { noDataLabel } from "../../utils/constants";

function MessageTest({
  message,
  isOwnMessage,
}: {
  message: IChatMessage;
  isOwnMessage: boolean;
}) {
  const messageClassname = isOwnMessage
    ? `${messageStyles.message} ${messageStyles.ownMessage}`
    : messageStyles.message;
  const containerClassname = isOwnMessage
    ? messageStyles.ownMessageContainer
    : messageStyles.messageContainer;

  const therapist = useAppSelector(userSelector);

  const avatar = isOwnMessage ? therapist.avatar ?? questionnaireDoctor : defaultAvatar;
  const alt = isOwnMessage ? therapist.FirstName : noDataLabel;

  return (
    <>
      <div className={containerClassname}>
        <img src={avatar} alt={alt} className={messageStyles.avatar} />
        <div className={messageClassname}>
          <p className={messageStyles.text}>
            {message.content.split("\n").map((part, index) => (
              <span key={index} className={messageStyles.textPart}>
                {part}
              </span>
            ))}
          </p>
          <p
            className={`${messageStyles.time} ${isOwnMessage && messageStyles.time_ownMessage
              }`}
          >
            {message.sentDate ? getTimeFromTimestamp(message.sentDate) : ""}
          </p>
        </div>
      </div>
    </>
  );
}

export default MessageTest;
